<template>
	<div class="alert alert-danger alert-dismissible fade show mt-4" role="alert">
		{{ text }}
		<button type="button" class="close" data-dismiss="alert" aria-label="Close" @click="onClose"><span aria-hidden="true">×</span></button>
	</div>
</template>

<script>
export default {
	props: ['text'],
	methods: {
		onClose () {
			this.$emit('dismissed');
		}
	}
}
</script>
