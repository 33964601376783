import {
	FILE_REMOVE_UPLOADED,
	FILE_REMOVE_UPLOADED_SUCCESS,
	FILE_REMOVE_UPLOADED_ERROR,
	FILE_DOWNLOAD,
	FILE_DOWNLOAD_SUCCESS,
	FILE_DOWNLOAD_ERROR,
	CHANGE_ARCHIVE,
	CHANGE_ARCHIVE_SUCCESS,
	CHANGE_ARCHIVE_ERROR
} from "../actions/files";
import Vue from "vue";
import FilesService from '@/services/files';

const state = {
	status: ''
};

const getters = {
};

const actions = {
	[FILE_REMOVE_UPLOADED]: ({ commit, dispatch }, data) => {
		let requestData = {
			filepath: data.filepath
		}
		return new Promise((resolve, reject) => {
			commit(FILE_REMOVE_UPLOADED);
			FilesService.removeUploaded(requestData)
				.then(data => {
					commit(FILE_REMOVE_UPLOADED_SUCCESS);
					resolve(data);
				})
				.catch(error => {
					commit(FILE_REMOVE_UPLOADED_ERROR, error);
					reject(error);
				});
		});
	},
	[FILE_DOWNLOAD]: ({ commit }, data) => {
		let requestData = {
			filepath: data.filepath
		}
		if (data.eslogToPdf != null) {
			requestData.eslogToPdf = data.eslogToPdf;
		}
		return new Promise((resolve, reject) => {
			commit(FILE_DOWNLOAD);
			FilesService.download(requestData)
				.then(responseData => {
					commit(FILE_DOWNLOAD_SUCCESS, responseData);
					resolve(responseData);
				})
				.catch(error => {
					commit(FILE_DOWNLOAD_ERROR, error);
					reject(error);
				});
		});
	},
	[CHANGE_ARCHIVE]: ({ commit }, data) => {
		let requestData = {
			id: data.id,
			model: data.model
		}
		return new Promise((resolve, reject) => {
			commit(CHANGE_ARCHIVE);
			FilesService.changeArchive(requestData)
				.then(responseData => {
					commit(CHANGE_ARCHIVE_SUCCESS, responseData);
					resolve(responseData);
				})
				.catch(error => {
					commit(CHANGE_ARCHIVE_ERROR, error);
					reject(error);
				});
		});
	},
};

const mutations = {
	[FILE_REMOVE_UPLOADED]: state => {
		state.status = 'loading';
	},
	[FILE_REMOVE_UPLOADED_SUCCESS]: (state, details) => {
		state.status = 'success';
	},
	[FILE_REMOVE_UPLOADED_ERROR]: state => {
		state.status = 'error';
	},
	[FILE_DOWNLOAD]: state => {
		state.status = 'loading';
	},
	[FILE_DOWNLOAD_SUCCESS]: (state, details) => {
		state.status = 'success';
	},
	[FILE_DOWNLOAD_ERROR]: state => {
		state.status = 'error';
	},
	[CHANGE_ARCHIVE]: state => {
		state.status = 'loading';
	},
	[CHANGE_ARCHIVE_SUCCESS]: (state, details) => {
		state.status = 'success';
	},
	[CHANGE_ARCHIVE_ERROR]: state => {
		state.status = 'error';
	},
};

export default {
	state,
	getters,
	actions,
	mutations
};