import { AUTH_CLEAR } from '@/store/actions/auth';

const ERROR_HANDLER = (url, error, data, reject) => {
    console.log(`Call to ${url}`, data);
    console.log(error);
    reject(error);
}
const SUCCESS_HANDLER = (url, response, data, resolve) => {
    console.log(`Call to ${url}`, data);
    console.log(response);
    resolve(response);
}
const UNAUTHORIZED_ERROR_HANDLER = (error, store, router) => {
    return new Promise(() => {
      if (error.response && error.response.status === 401) {
        store.dispatch(AUTH_CLEAR).then(() => {
          router.push('/login');
        });
      }
      throw error;
    });
  }
export default {
    ERROR_HANDLER,
    SUCCESS_HANDLER,
    UNAUTHORIZED_ERROR_HANDLER
};