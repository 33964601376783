import {
	ORDER_CREATE,
	ORDER_CREATE_SUCCESS,
	ORDER_CREATE_ERROR,
	ORDER_DELETE,
	ORDER_DELETE_SUCCESS,
	ORDER_DELETE_ERROR,
	ORDERS_LIST_FILTERED,
	ORDERS_LIST_FILTERED_SUCCESS,
	ORDERS_LIST_FILTERED_ERROR,
	ORDERS_DOWNLOAD_FILE,
	ORDERS_DOWNLOAD_FILE_SUCCESS,
	ORDERS_DOWNLOAD_FILE_ERROR,
} from "../actions/orders";
import Vue from "vue";
import OrdersService from '@/services/orders';

const state = {
	status: ''
};

const getters = {
};

const actions = {
	[ORDER_CREATE]: ({ commit, dispatch }, data) => {
		let requestData = {
			id: data.order.id,
			client_id: data.order.clientId,
			num_order: data.order.numOrder,
			type: data.order.type,
			valid_from: data.order.validFrom,
			valid_until: data.order.validUntil,
			description: data.order.description,
			orderAttachments: data.orderAttachments,
			orderAttachmentsToRemove: data.orderAttachmentsToRemove
		}
		return new Promise((resolve, reject) => {
			commit(ORDER_CREATE);
			OrdersService.create(requestData)
				.then(data => {
					commit(ORDER_CREATE_SUCCESS);
					resolve(data);
				})
				.catch(error => {
					commit(ORDER_CREATE_ERROR, error);
					reject(error);
				});
		});
	},
	[ORDER_DELETE]: ({ commit, dispatch }, id) => {
		let requestData = {
			id: id,
		}
		return new Promise((resolve, reject) => {
			commit(ORDER_DELETE);
			OrdersService.delete(requestData)
				.then(data => {
					commit(ORDER_DELETE_SUCCESS);
					resolve(data);
				})
				.catch(error => {
					commit(ORDER_DELETE_ERROR, error);
					reject(error);
				});
		});
	},
	[ORDERS_LIST_FILTERED]: ({ commit }, data) => {
		let requestData = {
			size: data.tableProps.itemsPerPage,
			page: data.tableProps.currentPage,
			sort: data.ctx != null ? data.ctx.sortBy : null,
			desc: data.ctx != null ? data.ctx.sortDesc : false
		}
		console.log('clientid', data.tableProps.inputFilter.clientId);
		if (data.tableProps.inputFilter.clientId) {
			requestData.clientIdFilter = data.tableProps.inputFilter.clientId;
		}
		if (data.tableProps.inputFilter.numOrder) {
			requestData.numOrderFilter = data.tableProps.inputFilter.numOrder;
		}
		if (data.tableProps.inputFilter.getAll) {
			requestData.getAll = data.tableProps.inputFilter.getAll;
		}
		return new Promise((resolve, reject) => {
			commit(ORDERS_LIST_FILTERED);
			OrdersService.listFiltered(requestData)
				.then(responseData => {
					commit(ORDERS_LIST_FILTERED_SUCCESS, responseData);
					resolve(responseData);
				})
				.catch(error => {
					commit(ORDERS_LIST_FILTERED_ERROR, error);
					reject(error);
				});
		});
	},
	[ORDERS_DOWNLOAD_FILE]: ({ commit }, data) => {
		let requestData = {
			filepath: data.filepath
		}
		return new Promise((resolve, reject) => {
			commit(ORDERS_DOWNLOAD_FILE);
			EbillsService.downloadFile(requestData)
				.then(responseData => {
					commit(ORDERS_DOWNLOAD_FILE_SUCCESS, responseData);
					resolve(responseData);
				})
				.catch(error => {
					commit(ORDERS_DOWNLOAD_FILE_ERROR, error);
					reject(error);
				});
		});
	},
	
};

const mutations = {
	[ORDER_CREATE]: state => {
		state.status = 'loading';
	},
	[ORDER_CREATE_SUCCESS]: (state, details) => {
		state.status = 'success';
	},
	[ORDER_CREATE_ERROR]: state => {
		state.status = 'error';
	},
	[ORDER_DELETE]: state => {
		state.status = 'loading';
	},
	[ORDER_DELETE_SUCCESS]: (state, details) => {
		state.status = 'success';
	},
	[ORDER_DELETE_ERROR]: state => {
		state.status = 'error';
	},
	[ORDERS_LIST_FILTERED]: state => {
		state.status = 'loading';
	},
	[ORDERS_LIST_FILTERED_SUCCESS]: (state, responseData) => {
		state.status = 'success';
	},
	[ORDERS_LIST_FILTERED_ERROR]: state => {
		state.status = 'error';
	},
	[ORDERS_DOWNLOAD_FILE]: state => {
		state.status = 'loading';
	},
	[ORDERS_DOWNLOAD_FILE_SUCCESS]: (state, responseData) => {
		state.status = 'success';
	},
	[ORDERS_DOWNLOAD_FILE_ERROR]: state => {
		state.status = 'error';
	},
};

export default {
	state,
	getters,
	actions,
	mutations
};