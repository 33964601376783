const settings = {
	viewDFormat: 'dd. MM. yyyy',
	viewDtFormat: 'dd.MM.yyyy HH:mm',
	filterTimeoutLength: 1000,
	emailRegex: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
	notificationMethodOptions: [
		{ value: 'email', label: 'Elektronski naslov' },
		{ value: 'halcom', label: 'Halcom' },
	],
	orderTypeOptions: [
		{ value: 'single', label: 'Enkratna' },
		{ value: 'multiple', label: 'Terminska' },
	],
	ebillAreaOptions: [
		{
			label: 'CDP',
			value: 'CDP',
			active: false
		},
		{
			label: 'Toyota',
			value: 'Toyota',
			active: false
		},
		{
			label: 'Form.NET',
			value: 'Form.NET',
			active: false
		},
		{
			label: 'Vasco',
			value: 'Vasco',
			active: false
		}
	],
	ebillStatusOptions: [
		{
			label: 'V pripravi',
			value: 'inPreparation',
			active: false
		},
		{
			label: 'Pripravljen',
			value: 'prepared',
			active: false
		},
		{
			label: 'Poslan',
			value: 'sended',
			active: false
		}
	]
}
export default settings
