import {
	EBILL_ATTACHMENTS_ADD,
	EBILL_ATTACHMENTS_ADD_SUCCESS,
	EBILL_ATTACHMENTS_ADD_ERROR,
	EBILL_ATTACHMENT_DELETE,
	EBILL_ATTACHMENT_DELETE_SUCCESS,
	EBILL_ATTACHMENT_DELETE_ERROR
} from "../actions/ebillsAttachments";
import Vue from "vue";
import EbillsAttachmentsService from '@/services/ebillsAttachments';

const state = {
	status: ''
};

const getters = {
};

const actions = {
	[EBILL_ATTACHMENTS_ADD]: ({ commit, dispatch }, data) => {
		console.log('modules', data);
		let requestData = {
			ebillId: data.ebillId,
			attachments: data.attachments
		}
		console.log('modules', requestData);
		return new Promise((resolve, reject) => {
			commit(EBILL_ATTACHMENTS_ADD);
			EbillsAttachmentsService.add(requestData)
				.then(data => {
					commit(EBILL_ATTACHMENTS_ADD_SUCCESS);
					resolve(data);
				})
				.catch(error => {
					commit(EBILL_ATTACHMENTS_ADD_ERROR, error);
					reject(error);
				});
		});
	},
	[EBILL_ATTACHMENT_DELETE]: ({ commit, dispatch }, data) => {
		let requestData = {
			id: data.id,
		}
		return new Promise((resolve, reject) => {
			commit(EBILL_ATTACHMENT_DELETE);
			EbillsAttachmentsService.delete(requestData)
				.then(data => {
					commit(EBILL_ATTACHMENT_DELETE_SUCCESS);
					resolve(data);
				})
				.catch(error => {
					commit(EBILL_ATTACHMENT_DELETE_ERROR, error);
					reject(error);
				});
		});
	},
};

const mutations = {
	[EBILL_ATTACHMENTS_ADD]: state => {
		state.status = 'loading';
	},
	[EBILL_ATTACHMENTS_ADD_SUCCESS]: (state, details) => {
		state.status = 'success';
	},
	[EBILL_ATTACHMENTS_ADD_ERROR]: state => {
		state.status = 'error';
	},
	[EBILL_ATTACHMENT_DELETE]: state => {
		state.status = 'loading';
	},
	[EBILL_ATTACHMENT_DELETE_SUCCESS]: (state, details) => {
		state.status = 'success';
	},
	[EBILL_ATTACHMENT_DELETE_ERROR]: state => {
		state.status = 'error';
	},
};

export default {
	state,
	getters,
	actions,
	mutations
};