import {
	CLIENT_CREATE,
	CLIENT_CREATE_SUCCESS,
	CLIENT_CREATE_ERROR,
	CLIENT_DELETE,
	CLIENT_DELETE_SUCCESS,
	CLIENT_DELETE_ERROR,
	CLIENTS_LIST_FILTERED,
	CLIENTS_LIST_FILTERED_SUCCESS,
	CLIENTS_LIST_FILTERED_ERROR,
	CLIENT_SEARCH_BY_TAX_NUMBER_FROM_INETIS,
	CLIENT_SEARCH_BY_TAX_NUMBER_FROM_INETIS_SUCCESS,
	CLIENT_SEARCH_BY_TAX_NUMBER_FROM_INETIS_ERROR,
	CLIENT_SEARCH,
	CLIENT_SEARCH_SUCCESS,
	CLIENT_SEARCH_ERROR,
	CLIENTS_LIST_ALL,
	CLIENTS_LIST_ALL_SUCCESS,
	CLIENTS_LIST_ALL_ERROR,
} from "../actions/clients";
import Vue from "vue";
import ClientsService from '@/services/clients';

const state = {
	status: '',
	initialClients: null,
	allClients: null
};

const getters = {
	getInitialClients: state => state.initialClients,
	getAllClients: state => state.allClients
};

const actions = {
	[CLIENT_CREATE]: ({ commit, dispatch }, data) => {
		let requestData = {
			id: data.client.id,
			first_name: data.client.firstName,
			last_name: data.client.lastName,
			is_company: data.client.isCompany,
			company_title: data.client.companyTitle,
			company_contact_person: data.client.companyContactPerson,
			company_tax_number: data.client.companyTaxNumber,
			trr: data.client.trr,
			street: data.client.street,
			city_code: data.client.cityCode,
			city: data.client.city,
			phone_number: data.client.phoneNumber,
			email: data.client.email,
			notification_method: data.client.notificationMethod,
			note: data.client.note,
		}
		return new Promise((resolve, reject) => {
			commit(CLIENT_CREATE);
			ClientsService.create(requestData)
				.then(data => {
					commit(CLIENT_CREATE_SUCCESS);
					resolve(data);
				})
				.catch(error => {
					commit(CLIENT_CREATE_ERROR, error);
					reject(error);
				});
		});
	},
	[CLIENT_DELETE]: ({ commit, dispatch }, id) => {
		let requestData = {
			id: id,
		}
		return new Promise((resolve, reject) => {
			commit(CLIENT_DELETE);
			ClientsService.delete(requestData)
				.then(data => {
					commit(CLIENT_DELETE_SUCCESS);
					resolve(data);
				})
				.catch(error => {
					commit(CLIENT_DELETE_ERROR, error);
					reject(error);
				});
		});
	},
	[CLIENTS_LIST_FILTERED]: ({ commit }, data) => {
		let requestData = {
			size: data.tableProps.itemsPerPage,
			page: data.tableProps.currentPage,
			sort: data.ctx.sortBy,
			desc: data.ctx.sortDesc,
		}
		if (data.tableProps.inputFilter.email) {
			requestData.emailFilter = data.tableProps.inputFilter.email;
		}
		if (data.tableProps.inputFilter.title) {
			requestData.titleFilter = data.tableProps.inputFilter.title;
		}
		return new Promise((resolve, reject) => {
			commit(CLIENTS_LIST_FILTERED);
			ClientsService.listFiltered(requestData)
				.then(responseData => {
					commit(CLIENTS_LIST_FILTERED_SUCCESS, responseData);
					resolve(responseData);
				})
				.catch(error => {
					commit(CLIENTS_LIST_FILTERED_ERROR, error);
					reject(error);
				});
		});
	},
	[CLIENT_SEARCH_BY_TAX_NUMBER_FROM_INETIS]: ({ commit, dispatch }, data) => {
		let requestData = {
			tax_number: data.taxNumber,
		}
		return new Promise((resolve, reject) => {
			commit(CLIENT_SEARCH_BY_TAX_NUMBER_FROM_INETIS);
			ClientsService.searchByTaxNumberFromInetis(requestData)
				.then(data => {
					commit(CLIENT_SEARCH_BY_TAX_NUMBER_FROM_INETIS_SUCCESS);
					resolve(data);
				})
				.catch(error => {
					commit(CLIENT_SEARCH_BY_TAX_NUMBER_FROM_INETIS_ERROR, error);
					reject(error);
				});
		});
	},
	[CLIENT_SEARCH]: ({ commit, dispatch }, data) => {
		let requestData = {
			searchString: data.searchString,
		}
		return new Promise((resolve, reject) => {
			commit(CLIENT_SEARCH);
			ClientsService.search(requestData)
				.then(data => {
					commit(CLIENT_SEARCH_SUCCESS, data);
					resolve(data);
				})
				.catch(error => {
					commit(CLIENT_SEARCH_ERROR, error);
					reject(error);
				});
		});
	},
	[CLIENTS_LIST_ALL]: ({ commit, dispatch }, data) => {
		return new Promise((resolve, reject) => {
			commit(CLIENTS_LIST_ALL);
			ClientsService.listAll()
				.then(data => {
					commit(CLIENTS_LIST_ALL_SUCCESS, data);
					resolve(data);
				})
				.catch(error => {
					commit(CLIENTS_LIST_ALL_ERROR, error);
					reject(error);
				});
		});
	},
};

const mutations = {
	[CLIENT_CREATE]: state => {
		state.status = 'loading';
	},
	[CLIENT_CREATE_SUCCESS]: (state, details) => {
		state.status = 'success';
	},
	[CLIENT_CREATE_ERROR]: state => {
		state.status = 'error';
	},
	[CLIENT_DELETE]: state => {
		state.status = 'loading';
	},
	[CLIENT_DELETE_SUCCESS]: (state, details) => {
		state.status = 'success';
	},
	[CLIENT_DELETE_ERROR]: state => {
		state.status = 'error';
	},
	[CLIENTS_LIST_FILTERED]: state => {
		state.status = 'loading';
	},
	[CLIENTS_LIST_FILTERED_SUCCESS]: (state, responseData) => {
		state.status = 'success';
	},
	[CLIENTS_LIST_FILTERED_ERROR]: state => {
		state.status = 'error';
	},
	[CLIENT_SEARCH_BY_TAX_NUMBER_FROM_INETIS]: state => {
		state.status = 'loading';
	},
	[CLIENT_SEARCH_BY_TAX_NUMBER_FROM_INETIS_SUCCESS]: (state, details) => {
		state.status = 'success';
	},
	[CLIENT_SEARCH_BY_TAX_NUMBER_FROM_INETIS_ERROR]: state => {
		state.status = 'error';
	},
	[CLIENT_SEARCH]: state => {
		state.status = 'loading';
	},
	[CLIENT_SEARCH_SUCCESS]: (state, responseData) => {
		console.log('clientSearchSuccess');
		state.status = 'success';
		if (responseData.message == 'initialSearch') {
			Vue.set(state, 'initialClients', responseData.clients);
		}
	},
	[CLIENT_SEARCH_ERROR]: state => {
		console.log('clientSearchSuccess');
		state.status = 'error';
		Vue.set(state, 'initialClients', null);
	},
	[CLIENTS_LIST_ALL]: state => {
		state.status = 'loading';
	},
	[CLIENTS_LIST_ALL_SUCCESS]: (state, responseData) => {
		state.status = 'success';
		Vue.set(state, 'allClients', responseData.clients);
	},
	[CLIENTS_LIST_ALL_ERROR]: state => {
		state.status = 'error';
		Vue.set(state, 'allClients', null);
	},
};

export default {
	state,
	getters,
	actions,
	mutations
};