<template>
	<b-modal :no-close-on-backdrop="true" :hide-header-close="isLoading" ref="modal" :title="title" :size="modalSize" class="modal-primary" @hidden="clearModalData">
		<b-tabs v-model="selectedTabIndex">
			<b-tab title="Podatki stranke" class="pt-2" card>
				<CRow>
					<CCol col=4>
						<span class="costum-switch prepend">
							Podjetje
						</span>
						<span class="costum-switch content">
							<CSwitch
								class="pt-2"
								:disabled="isLoading"
								variant="opposite"
								color="primary"
								v-bind="isCompanyLabelTxt"
								:checked.sync="client.isCompany">
							</CSwitch>
						</span>
					</CCol>
					<CCol>
						<CSelect
							class="pt-1"
							prepend="Način obveščanja:"
							:options="settings.notificationMethodOptions"
							:value.sync="client.notificationMethod"
							custom
						/>
					</CCol>
				</CRow>
				<div v-if="client.isCompany">
					<CRow>
						<CCol>
							<CInput
								label="Naziv"
								v-model="client.companyTitle"/>
						</CCol>
					</CRow>
					<CRow>
						<CCol>
							<CInput
								label="Kontaktna oseba"
								v-model="client.companyContactPerson"/>
						</CCol>
						<CCol>
							<CInput
								label="Davčna številka"
								v-model="client.companyTaxNumber">
								<template #append>
									<CButton color="primary" size="sm" title="Poišči po davčni številki" @click.stop="searchByTaxNumber()">
										<CIcon name="cil-magnifying-glass"/>
									</CButton>
								</template>
							</CInput>
						</CCol>
					</CRow>
					<CRow>
					</CRow>
				</div>
				<div v-else>
					<CRow>
						<CCol>
							<CInput
								label="Ime"
								v-model="client.firstName"/>
						</CCol>
						<CCol>
							<CInput
								label="Priimek"
								v-model="client.lastName"/>
						</CCol>
					</CRow>
				</div>
				<CRow>
					<CCol>
						<CInput
							label="Transakcijski račun"
							v-model="client.trr"/>
					</CCol>
				</CRow>
				<CRow>
					<CCol>
						<CInput
							label="Ulica in hišna št."
							v-model="client.street"/>
					</CCol>
				</CRow>
				<CRow>
					<CCol col=4>
						<CInput
							label="Poštna št."
							v-model="client.cityCode"/>
					</CCol>
					<CCol>
						<CInput
							label="Kraj"
							v-model="client.city"/>
					</CCol>
				</CRow>
				<CRow>
					<CCol col=4>
						<CInput
							label="Telefonska številka"
							v-model="client.phoneNumber"/>
					</CCol>
					<CCol>
						<CInput
							label="Email"
							:isValid="isValidEmail"
							:invalid-feedback="emailInvalidFeedback"
							v-model="client.email"/>
					</CCol>
				</CRow>
				<CRow>
				</CRow>
				<CRow>
					<CCol>
						<CTextarea
							label="Opomba"
							rows="3"
							v-model="client.note"/>
					</CCol>
				</CRow>
			</b-tab>
			<b-tab v-if="mode === mode_EDIT2" title="E-računi">
				<b-card>
					<vue-loading :active.sync="ebillsOfClientTableProps.isLoading" :is-full-page="false"/>
					<div slot="header">
						<b>Seznam e-računov</b>
						<div class="card-header-actions">
							<CLink title="Osveži tabelo" href="#" class="card-header-action btn-setting" @click.stop="click_ebillsOfClientTableRefresh">
								<CIcon name="cil-loop-circular" class="mb-1"/>
							</CLink>
							<CLink title="Ponastavi filtre" href="#" class="card-header-action btn-setting" @click.stop="click_ebillsOfClientTableResetFilters">
								<CIcon name="cil-x" class="mb-1"/>
							</CLink>
						</div>
					</div>
					<b-table
						ref="ebillsOfClientTable"
						responsive="sm"
						striped
						show-empty
						hover
						selectable
						:empty-text="ebillsOfClientTableProps.empty_text"
						:empty-filtered-text="ebillsOfClientTableProps.empty_filtered_text"
						:fields="ebillsOfClientTableProps.fields"
						:current-page="ebillsOfClientTableProps.currentPage"
						:per-page="ebillsOfClientTableProps.itemsPerPage"
						:items="ebillsOfClientTable_items"
						:sort-by.sync="ebillsOfClientTableProps.sortBy"
						:sort-desc.sync="ebillsOfClientTableProps.sortDesc"
						@row-clicked="rowClicked_ebillsOfClientTable">
						<template slot="top-row">
								<td></td>
								<td><b-form-input v-model="ebillsOfClientTableProps.inputFilter.envelopeDocId" type="text" @input="ebillsOfClientTableTextFilter_change"/></td>
								<td><b-form-input v-model="ebillsOfClientTableProps.inputFilter.ebillNumber" type="text" @input="ebillsOfClientTableTextFilter_change"/></td>
								<td></td>
								<td></td>
								<td><ebill-area-filter ref="ebillAreaFilter" @change="change_ebillAreaFilter"/></td>
								<td v-if="tmpShowEbillsActions"></td>
						</template>
						<template v-slot:cell(created_at)="data">
							{{ data.item.created_at | viewDate }}
						</template>
						<template v-slot:cell(status)="data" >
							<b-badge :title="ebillStatus(data).title"  :variant="ebillStatus(data).variant" size="sm">
								{{ ebillStatus(data).label }}
							</b-badge>
						</template>
						<template v-slot:cell(envelope_doc_id)="data">
							<div v-if="data.item.envelope != null">{{ data.item.envelope.doc_id }}</div>
						</template>
						<template v-slot:cell(ebill_number)="data">
							<div v-if="data.item.ebill_file != null">{{ data.item.ebill_file.ebill_number }}</div>
						</template>
						<template v-if="tmpShowEbillsActions" v-slot:cell(actions)="data">
							<div>
								<b-button v-if="false" title="Prikaži e-račun" variant="info" @click.stop="click_ebillShowDetails(data.item)" size="sm" class="mr-2">
									<CIcon name="cil-zoom-in"/>
								</b-button>
								<b-button title="Arhiviraj e-račun" variant="danger" @click.stop="click_ebillArchive(data.item)" size="sm" class="mr-2">
									<CIcon name="cil-briefcase"/>
								</b-button>
								<b-button v-if="ebillStatus(data).label == 'Pripravljen'" title="Pošlji e-račun" variant="primary" @click.stop="click_ebillSend(data.item)" size="sm" class="mr-2">
									<CIcon name="cil-send"/>
								</b-button>
							</div>
						</template>
						<template slot="row-details" slot-scope="data">
							<ebill-details-card :clientModal="true" ref="test" @emit_ebillPrepared="emit_ebillPrepared" @emit_ebillRejected="emit_ebillRejected" v-bind:ebill="data.item"/>
						</template>
					</b-table>
					<nav>
						<b-row>
							<b-col>
								<b-pagination :total-rows="ebillsOfClientTableProps.totalItems" :per-page="ebillsOfClientTableProps.itemsPerPage" v-model="ebillsOfClientTableProps.currentPage" prev-text="Prejšnja" next-text="Naslednja" hide-goto-end-buttons />
							</b-col>
							
							<b-col cols=2>
								<a class="page-link rows_total float-right">Št. vrstic: {{ ebillsOfClientTableProps.totalItems }}</a>
							</b-col>
							<b-col cols=1>
								<b-form-select v-model="ebillsOfClientTableProps.itemsPerPage" :options="ebillsOfClientTableProps.options_numRowsOnPage"></b-form-select>
							</b-col>
						</b-row>
					</nav>
				</b-card>
			</b-tab>
			<b-tab v-if="mode === mode_EDIT2" title="Naročilnice">
				<b-card>
					<vue-loading :active.sync="ordersOfClientTableProps.isLoading" :is-full-page="false"/>
					<div slot="header">
						<b>Seznam naročilnic</b>
						<div class="card-header-actions">
							<CLink title="Osveži tabelo" href="#" class="card-header-action btn-setting" @click.stop="click_ordersOfClientTableRefresh">
								<CIcon name="cil-loop-circular" class="mb-1"/>
							</CLink>
							<CLink title="Ponastavi filtre" href="#" class="card-header-action btn-setting" @click.stop="click_ordersOfClientTableResetFilters">
								<CIcon name="cil-x" class="mb-1"/>
							</CLink>
							<CLink title="Dodaj naročilnico" href="#" class="card-header-action btn-setting" @click.stop="click_orderCreate">
								<CIcon name="cil-plus"/>
							</CLink>
						</div>
					</div>
					<b-table
						ref="ordersOfClientTable"
						responsive="sm"
						striped
						show-empty
						hover
						:empty-text="ordersOfClientTableProps.empty_text"
						:empty-filtered-text="ordersOfClientTableProps.empty_filtered_text"
						:fields="ordersOfClientTableProps.fields"
						:current-page="ordersOfClientTableProps.currentPage"
						:per-page="ordersOfClientTableProps.itemsPerPage"
						:items="ordersOfClientTable_items"
						:sort-by.sync="ordersOfClientTableProps.sortBy"
						:sort-desc.sync="ordersOfClientTableProps.sortDesc"
						@row-clicked="rowClicked_ordersTable">
						<template slot="top-row">
							<td></td>
							<td><b-form-input v-model="ordersOfClientTableProps.inputFilter.numOrder" type="text" @input="ordersOfClientTableTextFilter_change"/></td>
							<td></td>
							<td></td>
						</template>
						<template v-slot:cell(type)="data" >
							<b-badge :title="orderType(data).title" :variant="orderType(data).variant" size="sm" class="mt-2">
								{{ orderType(data).label }}
							</b-badge>
						</template>
						<template v-slot:cell(actions)="data" >
							<b-button title="Odstrani" variant="danger" @click.stop="click_orderDelete(data.item)" size="sm">
								<CIcon name="cil-trash"/>
							</b-button>
						</template>
					</b-table>
					<nav>
						<b-row>
							<b-col>
								<b-pagination :total-rows="ordersOfClientTableProps.totalItems" :per-page="ordersOfClientTableProps.itemsPerPage" v-model="ordersOfClientTableProps.currentPage" prev-text="<" next-text=">" hide-goto-end-buttons />
							</b-col>
							
							<b-col cols=4>
								<a class="page-link rows_total float-right">Št. vrstic: {{ ordersOfClientTableProps.totalItems }}</a>
							</b-col>
							<b-col cols=3>
								<b-form-select v-model="ordersOfClientTableProps.itemsPerPage" :options="ordersOfClientTableProps.options_numRowsOnPage"></b-form-select>
							</b-col>
						</b-row>
					</nav>
				</b-card>
			</b-tab>
		</b-tabs>
		<CAlert v-show="message.text != null" :color="message.type">
			{{ message.text }}
		</CAlert>
		<template slot="modal-footer" slot-scope="{ cancel }">
			<b-container fluid class="p-0">
				<b-row>
					<b-col align-self="end" class="ma_pos">
						<b-button :disabled="isLoading" style="margin-right:5px;" variant="secondary" @click="cancel()">Prekliči</b-button>
						<b-button :disabled="okButton.disabled" variant="primary" @click.stop="onOkModal()">{{ okButton.label }}</b-button>
					</b-col>
				</b-row>
			</b-container>
		</template>
		<vue-loading :active.sync="isLoading" :is-full-page="false"/>
		<order-create-edit-modal ref="orderCreateEditModal" @emit_orderCreatedOrEdited="emit_orderCreatedOrEdited"/>
	</b-modal>
</template>

<script>
import settings from '@/settings.js';
import { CLIENT_CREATE, CLIENT_SEARCH_BY_TAX_NUMBER_FROM_INETIS } from "@/store/actions/clients";
import { EBILLS_LIST_FILTERED } from "@/store/actions/ebills";
import { ORDERS_LIST_FILTERED, ORDER_DELETE } from "@/store/actions/orders";
import EbillAreaFilter from '@/views/components/DropdownFilters/EbillAreaFilter';
import EbillDetailsCard from '@/views/components/EbillDetailsCard';
import OrderCreateEditModal from '@/views/modals/OrderCreateEdit';

export default {
	name: 'client-create-edit-modal',
	components: {
		'ebill-details-card': EbillDetailsCard,
		'ebill-area-filter': EbillAreaFilter,
		'order-create-edit-modal': OrderCreateEditModal
	},
	props: {
	},
	methods: {
		showModal: function (modalInput) {
			/*
				mode 1 => Create
				mode 2 => Edit
				mode 3 => EditClientDetailsOnly
			*/
			this.mode = modalInput.mode;
			this.initModal(modalInput.client);
		},
		initModal: function (client) {
			if (this.mode === this.mode_CREATE1) {
				this.title = 'Dodajanje stranke';
				this.okButton.label = 'Dodaj';
			} else if (this.mode === this.mode_EDIT2 || this.mode === this.mode_EDITDETAILSONLY3) {
				this.title = 'Urejanje stranke ' + this.mixinGetClientFullName(client) + '';
				this.initClient(client);
				this.okButton.label = 'Shrani';
			} else {
				this.mode = this.mode_UNKNOWN0;
				this.title = 'Neznan način';
				this.okButton.label = 'Zapri';
			}
			this.$refs.modal.show();
		},
		initClient: function (client) {
			this.client.id = client.id;
			this.client.firstName = client.first_name;
			this.client.lastName = client.last_name;
			this.client.isCompany = client.is_company == 1 ? true : false;
			this.client.companyTitle = client.company_title;
			this.client.companyContactPerson = client.company_contact_person;
			this.client.companyTaxNumber = client.company_tax_number;
			this.client.trr = client.trr;
			this.client.street = client.street;
			this.client.cityCode = client.city_code;
			this.client.city = client.city;
			this.client.phoneNumber = client.phone_number;
			this.client.email = client.email;
			if (settings.notificationMethodOptions.find((item) => item.value === client.notification_method)) {
				this.client.notificationMethod = client.notification_method;
			} else {
				this.showAlert('Izbran način obveščanja "' + client.notification_method + '" več ni mogoč, zato je nastavljen na privzeto vrednost.', this.ALERT_TYPE_ERROR, 5);
				this.client.notificationMethod = settings.notificationMethodOptions[0].value;
			}
			this.client.note = client.note;
			this.ebillsOfClientTableProps.inputFilter.clientId = client.id;
			this.ordersOfClientTableProps.inputFilter.clientId = client.id;
		},
		onOkModal: function () {
			this.startProgress();
			this.$store.dispatch(CLIENT_CREATE, { client: this.client })
				.then((responseData) => {
					this.stopProgress();
					this.$emit('emit_clientCreatedOrEdited', responseData);
					this.$refs.modal.hide();
				})
				.catch(error => {
					this.stopProgress();
					console.error(error);
					if(error.response && error.response.data && error.response.data.code) {
						if (this.mode === this.mode_CREATE1) {
							this.showAlert('Napaka pri dodajanju! (' + error.response.data.code + ')', this.ALERT_TYPE_ERROR);
						} else if (this.mode === this.mode_EDIT2) {
							this.showAlert('Napaka pri urejanju! (' + error.response.data.code + ')', this.ALERT_TYPE_ERROR);
						} else {
							this.showAlert('Neznan način modala! (' + error.response.data.code + ')', this.ALERT_TYPE_ERROR);
						}
					} else {
						this.showAlert('Napaka! Prosimo poizkusite kasneje.', this.ALERT_TYPE_ERROR);
					}
				});
		},
		clearModalData: function () {
			this.isLoading = false;
			this.title = '';
			this.okButton = {
				label: 'Shrani',
				progress: false,
				disabled: false
			};
			this.showAlert();
			this.mode = 0;
			this.resetClientObject();
			this.resetEbillsOfClientTableProps();
		},
		resetClientObject: function () {
			this.client.id = null;
			this.client.firstName = '';
			this.client.lastName = '';
			this.client.isCompany = false;
			this.client.companyTitle = '';
			this.client.companyContactPerson = '';
			this.client.companyTaxNumber = '';
			this.client.trr = '';
			this.client.street = '';
			this.client.cityCode = '';
			this.client.city = '';
			this.client.phoneNumber = '';
			this.client.email = null;
			this.client.notificationMethod = settings.notificationMethodOptions[0].value;
			this.client.note = '';
		},
		startProgress: function () {
			this.isLoading = true;
			this.okButton.disabled = true;
			this.okButton.progress = true;
		},
		stopProgress: function () {
			this.isLoading = false;
			this.okButton.disabled = false;
			this.okButton.progress = false;
		},
		showAlert: function (message, type, seconds) {
			if (message && type && seconds) {
				this.message.type = type;
				this.message.text = message;
				setTimeout(() => {
					this.showAlert();
				}, seconds * 1000);
			} else if (message && type) {
				this.message.type = type;
				this.message.text = message;
			} else if (message) {
				this.message.type = 'primary';
				this.message.text = message;
			} else {
				this.message.type = null;
				this.message.text = null;
			}
		},
		searchByTaxNumber: function () {
			this.startProgress();
			this.$store.dispatch(CLIENT_SEARCH_BY_TAX_NUMBER_FROM_INETIS, { taxNumber: this.client.companyTaxNumber })
				.then((responseData) => {
					this.stopProgress();
					if (responseData.message === 'found') {
						this.showAlert();
						this.client.companyTitle = responseData.title;
						this.client.street = responseData.street;
						this.client.cityCode = responseData.cityCode;
						this.client.city = responseData.city;
					} else if (responseData.message === 'multipleFound') {
						this.showAlert('Najdenih je več rezultatov, vnesite celotno davčno', 'warning');
					} else {
						this.showAlert('Ni najdenih rezultatov s to davčno številko.', 'warning');
					}
					console.log(responseData);
				})
				.catch(error => {
					this.stopProgress();
					console.error(error);
					if(error.response && error.response.data && error.response.data.code) {
						this.showAlert('Napaka pri iskanju z API-jem! (' + error.response.data.code + ')', this.ALERT_TYPE_ERROR);
					} else {
						this.showAlert('Napaka! Prosimo poizkusite kasneje.', this.ALERT_TYPE_ERROR);
					}
				});
		},
		click_ebillsOfClientTableRefresh: function () {
			this.$refs.ebillsOfClientTable.refresh();
		},
		click_ebillsOfClientTableResetFilters: function () {
			this.ebillsOfClientTableProps.inputFilter.envelopeDocId = null;
			this.ebillsOfClientTableProps.inputFilter.ebillNumber = null;
			this.ebillsOfClientTableProps.inputFilter.area = [];
			this.$refs.ebillAreaFilter.clear();
			this.$refs.ebillsOfClientTable.refresh();
		},
		ebillsOfClientTable_items: function (ctx) {
			this.ebillsOfClientTableProps.isLoading = true;
			return this.$store.dispatch(EBILLS_LIST_FILTERED, { ctx: ctx, tableProps: this.ebillsOfClientTableProps })
				.then((responseData) => {
					this.ebillsOfClientTableProps.isLoading = false;
					this.ebillsOfClientTableProps.items = responseData.ebillsPagination.data;
					this.ebillsOfClientTableProps.totalItems = responseData.ebillsPagination.total;
					if (this.successfulPreparedEbillId != null) {
						responseData.ebillsPagination.data.find((item) => {
							if (item.id === this.successfulPreparedEbillId) {
								this.rowClicked_ebillsOfClientTable(item);
							}
						});
						this.successfulPreparedEbillId = null;
					}
					if (this.successfulRejectedEbillId != null) {
						responseData.ebillsPagination.data.find((item) => {
							if (item.id === this.successfulRejectedEbillId) {
								this.rowClicked_ebillsOfClientTable(item);
							}
						});
						this.successfulRejectedEbillId = null;
					}
					this.ebillsOfClientTableProps.empty_text = 'Ni vnosov';
					return responseData.ebillsPagination.data;
				})
				.catch(error => {
					this.ebillsOfClientTableProps.isLoading = false;
					this.ebillsOfClientTableProps.items = [];
					this.ebillsOfClientTableProps.totalItems = 0;
					this.ebillsOfClientTableProps.empty_text = 'Napaka pri pridobivanju e-računov stranke';
					console.log(error);
					return [];
				});
		},
		ebillsOfClientTableTextFilter_change: function (value) {
			if (this.ebillsOfClientTableProps.textFilterTimeout !== null) {
				clearTimeout(this.ebillsOfClientTableProps.textFilterTimeout);
			}
			this.ebillsOfClientTableProps.textFilterTimeout = setTimeout(() => {
				this.$refs.ebillsOfClientTable.refresh();
			}, settings.filterTimeoutLength);
		},
		ebillStatus: function (data) {
			if (data.item.last_action == null && data.item.prepared_time == null) {
				return {
					variant: 'danger',
					title: 'E-račun še ni bil pripravljen.',
					label: 'V pripravi'
				}
			} else if (data.item.last_action == null && data.item.prepared_time != null) {
				return {
					variant: 'warning',
					title: 'E-račun je pripravil ' + data.item.prepared_user.name + ' dne ' + this.$options.filters.viewDate(data.item.prepared_time),
					label: 'Pripravljen'
				}
			}
			else {
				return {
					variant: 'success',
					title: 'Obvestilo tipa ' + data.item.last_action.type + ' poslal ' + data.item.last_action.user_added.name + ' dne ' + this.$options.filters.viewDate(data.item.last_action.commited_at),
					label: 'Poslan'
				}
			}
		},
		change_ebillAreaFilter: function (selectedItems) {
			if (selectedItems && selectedItems.length > 0) {
				this.ebillsOfClientTableProps.inputFilter.area = [];
				for (let item of selectedItems) {
					this.ebillsOfClientTableProps.inputFilter.area.push(item.value);
				}
			} else {
				this.ebillsOfClientTableProps.inputFilter.area = null;
			}
			this.$refs.ebillsOfClientTable.refresh();
		},
		resetEbillsOfClientTableProps: function() {
			this.ebillsOfClientTableProps.sortBy = 'created_at';
			this.ebillsOfClientTableProps.sortDesc = true;
			this.ebillsOfClientTableProps.currentPage = 1;
			this.ebillsOfClientTableProps.itemsPerPage = 10;
			this.ebillsOfClientTableProps.totalItems = 0;
			this.ebillsOfClientTableProps.isLoading = false;
			this.ebillsOfClientTableProps.empty_text = '';
			this.ebillsOfClientTableProps.empty_filtered_text = 'Ni zadetkov';
			this.ebillsOfClientTableProps.textFilterTimeout = null;
			this.ebillsOfClientTableProps.errorText = '';
			this.ebillsOfClientTableProps.inputFilter.envelopeDocId = null;
			this.ebillsOfClientTableProps.inputFilter.ebillNumber = null;
			this.ebillsOfClientTableProps.inputFilter.area = [];
			this.ebillsOfClientTableProps.inputFilter.clientId = null;
			this.selectedTabIndex = 0;
		},
		emit_ebillPrepared: function (preparedEbillId) {
			this.successfulPreparedEbillId = preparedEbillId;
			this.$refs.ebillsOfClientTable.refresh();
		},
		emit_ebillRejected: function (rejectedEbillId) {
			this.successfulRejectedEbillId = rejectedEbillId;
			this.$refs.ebillsOfClientTable.refresh();
		},
		click_ordersOfClientTableRefresh: function () {
			this.$refs.ordersOfClientTable.refresh();
		},
		click_ordersOfClientTableResetFilters: function () {
			this.ordersOfClientTableProps.inputFilter.numOrder = null;
			this.$refs.ordersOfClientTable.refresh();
		},
		ordersOfClientTable_items: function (ctx) {
			this.ordersOfClientTableProps.isLoading = true;
			return this.$store.dispatch(ORDERS_LIST_FILTERED, { ctx: ctx, tableProps: this.ordersOfClientTableProps })
				.then((responseData) => {
					this.ordersOfClientTableProps.isLoading = false;
					this.ordersOfClientTableProps.items = responseData.ordersPagination.data;
					this.ordersOfClientTableProps.totalItems = responseData.ordersPagination.total;
					this.ordersOfClientTableProps.empty_text = 'Ni vnosov';
					return responseData.ordersPagination.data;
				})
				.catch(error => {
					this.ordersOfClientTableProps.isLoading = false;
					this.ordersOfClientTableProps.items = [];
					this.ordersOfClientTableProps.totalItems = 0;
					this.ordersOfClientTableProps.empty_text = 'Napaka pri pridobivanju naročilnic stranke';
					console.log(error);
					return [];
				});
		},
		ordersOfClientTableTextFilter_change: function (value) {
			if (this.ordersOfClientTableProps.textFilterTimeout !== null) {
				clearTimeout(this.ordersOfClientTableProps.textFilterTimeout);
			}
			this.ordersOfClientTableProps.textFilterTimeout = setTimeout(() => {
				this.$refs.ordersOfClientTable.refresh();
			}, settings.filterTimeoutLength);
		},
		rowClicked_ebillsOfClientTable (row) {
			row._showDetails = !row._showDetails;
		},
		click_orderCreate: function () {
			let modalInput = {
				mode: 1, // create
				order: null,
				clientId: this.client.id
			}
			this.$refs.orderCreateEditModal.showModal(modalInput);
		},
		rowClicked_ordersTable: function (row) {
			let modalInput = {
				mode: 2, // edit
				order: row,
				clientId: this.client.id
			}
			this.$refs.orderCreateEditModal.showModal(modalInput);
		},
		emit_orderCreatedOrEdited: function (data) {
			if (data.message === 'created') {
				this.showAlert('Naročilnica številka ' + data.order.num_order + ' uspešno dodana.', this.ALERT_TYPE_SUCCESS, 3);
			} else if (data.message === 'edited') {
				this.showAlert('Naročilnica številka ' + data.order.num_order + ' uspešno urejena.', this.ALERT_TYPE_SUCCESS, 3);
			} else {
				this.showAlert('Neznan odziv strežnika.', this.ALERT_TYPE_ERROR, 3);
				
			}
			if (this.mode == this.mode_EDIT2) {
				this.$refs.ordersOfClientTable.refresh();
				this.$refs.ebillsOfClientTable.refresh();
			}
		},
		resetOrdersOfClientTableProps: function() {
			this.ordersOfClientTableProps.sortBy = 'created_at';
			this.ordersOfClientTableProps.sortDesc = true;
			this.ordersOfClientTableProps.currentPage = 1;
			this.ordersOfClientTableProps.itemsPerPage = 10;
			this.ordersOfClientTableProps.totalItems = 0;
			this.ordersOfClientTableProps.isLoading = false;
			this.ordersOfClientTableProps.empty_text = '';
			this.ordersOfClientTableProps.empty_filtered_text = 'Ni zadetkov';
			this.ordersOfClientTableProps.textFilterTimeout = null;
			this.ordersOfClientTableProps.errorText = '';
			this.ordersOfClientTableProps.inputFilter.clientId = null;
			this.ordersOfClientTableProps.inputFilter.numOrder = null;
			this.selectedTabIndex = 0;
		},
		orderType: function (data) {
			if (data.item.type == 'single') {
				return {
					variant: 'info',
					title: 'Naročilnica je enkratna.',
					label: 'Enkratna'
				}
			} else if (data.item.type == 'multiple') {
				return {
					variant: 'info',
					title: 'Naročilnica velja od ' + this.$options.filters.viewDate(data.item.valid_from) + ' do ' + this.$options.filters.viewDate(data.item.valid_until),
					label: 'Terminska'
				}
			}
			else {
				return {
					variant: 'dark',
					title: 'Neznan tip naročilnice: ' + data.item.type,
					label: data.item.type
				}
			}
		},
		click_orderDelete: function (data) {
			this.$bvModal.msgBoxConfirm('Želite izbrisati naročilnico številka ' + data.num_order + '?', {
				title: 'Potrdite brisanje naročilnice',
				size: 'sm',
				buttonSize: 'sm',
				okVariant: 'danger',
				okTitle: 'DA',
				cancelTitle: 'NE',
				footerClass: 'p-2',
				hideHeaderClose: false,
				centered: true
			}).then(value => {
				if (value === true) {
					this.isLoadingDeleting = true;
					this.$store.dispatch(ORDER_DELETE, { id: data.id })
						.then((responseData) => {
							this.isLoadingDeleting = false;
							this.showAlert('Naročilnica številka ' + data.num_order + ' uspešno izbrisana.', this.ALERT_TYPE_SUCCESS, 3);
							this.$refs.ordersOfClientTable.refresh();
						})
						.catch(error => {
							this.isLoadingDeleting = false;
							console.error(error);
							if(error.response && error.response.data && error.response.data.code) {
								this.showAlert('Napaka pri brisanju! (' + error.response.data.code + ')', this.ALERT_TYPE_ERROR, 3);
							} else {
								this.showAlert('Napaka pri brisanju! Prosimo poizkusite kasneje.', this.ALERT_TYPE_ERROR, 3);
							}
						});
				}
			}).catch(err => {
				console.error(err);
			})
		},
	},
	data: function () {
		return {
			settings,
			isLoading: false,
			title: '',
			okButton: {
				label: 'Shrani',
				progress: false,
				disabled: false
			},
			message: {
				type: null,
				text: null
			},
			ALERT_TYPE_SUCCESS: 'success',
			ALERT_TYPE_ERROR: 'danger',
			mode_UNKNOWN0: 0,
			mode_CREATE1: 1,
			mode_EDIT2: 2,
			mode_EDITDETAILSONLY3: 3,
			mode: 0,
			client: {
				id: null,
				firstName: '',
				lastName: '',
				isCompany: false,
				companyTitle: '',
				companyContactPerson: '',
				companyTaxNumber: '',
				trr: '',
				street: '',
				cityCode: '',
				city: '',
				phoneNumber: '',
				email: '',
				notificationMethod: settings.notificationMethodOptions[0].value,
				note: ''
			},
			isCompanyLabelTxt: {
				labelOn: 'Da',
				labelOff: 'Ne'
			},
			ebillsOfClientTableProps: {
				sortBy: 'created_at',
				sortDesc: true,
				fields: [
					{ key: 'id', label: 'Id', sortable: true, class: '' },
					{ key: 'envelope_doc_id', label: 'Id dokumenta', sortable: true, class: '' },
					{ key: 'ebill_number', label: 'Št. računa', sortable: true, class: '' },
					{ key: 'status', label: 'Status', sortable: false, class: '' },
					{ key: 'created_at', label: 'Dodan dne', sortable: true, class: '' },
					{ key: 'area', label: 'Pordočje', sortable: true, class: '' },
					// { key: 'actions', label: '#', sortable: false, class: 'tdEbillsActions' }, tmpShowEbillsActions
				],
				currentPage: 1,
				itemsPerPage: 10,
				totalItems: 0,
				isLoading: false,
				options_numRowsOnPage: [
					{ value: 10, text: '10' },
					{ value: 20, text: '20' },
					{ value: 100, text: '100' },
					{ value: 200, text: '200' }
				],
				empty_text: '',
				empty_filtered_text: 'Ni zadetkov',
				inputFilter: {
					clientId: null,
					envelopeDocId: null,
					ebillNumber: null,
					area: []
				},
				textFilterTimeout: null,
				errorText: ''
			},
			selectedTabIndex: 0,
			tmpShowEbillsActions: false,
			ordersOfClientTableProps: {
				sortBy: 'created_at',
				sortDesc: true,
				fields: [
					{ key: 'id', label: 'Id', sortable: true, class: '' },
					{ key: 'num_order', label: 'Številka naročila', sortable: true, class: '' },
					{ key: 'type', label: 'Tip', sortable: true, class: '' },
					{ key: 'actions', label: '#', sortable: false, class: 'tdOrderActions' }
				],
				currentPage: 1,
				itemsPerPage: 10,
				totalItems: 0,
				isLoading: false,
				options_numRowsOnPage: [
					{ value: 10, text: '10' },
					{ value: 20, text: '20' },
					{ value: 100, text: '100' },
					{ value: 200, text: '200' }
				],
				empty_text: '',
				empty_filtered_text: 'Ni zadetkov',
				inputFilter: {
					clientId: null,
					numOrder: null
				},
				textFilterTimeout: null,
				errorText: ''
			},
			successfulPreparedEbillId: null,
			successfulRejectedEbillId: null
		}
	},
	watch: {
	},
	computed: {
		modalSize () {
			if (this.selectedTabIndex === 1) {
				return 'xl';
			}
			return 'md';
		},
		isValidEmail() {
			if (!this.client.email && this.client.notificationMethod != 'email') {
				this.okButton.disabled = false;
				return null;
			} else if (settings.emailRegex.test(this.client.email)) {
				this.okButton.disabled = false;
				return true;
			} else {
				this.okButton.disabled = true;
				return false;
			}
		},
		emailInvalidFeedback() {
			if (!this.client.email && this.client.notificationMethod == 'email') {
				return 'Ob izbranem načinu obveščanja je email obvezen'
			} else if (!settings.emailRegex.test(this.client.email)) {
				return 'Nepravilna oblika emaila.';
			}
		}
	},
	created () {
	}
}
</script>

<style scoped>
.ma_pos {
	text-align: end;
}
</style>