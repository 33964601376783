export const ORDER_CREATE = "ORDER_CREATE";
export const ORDER_CREATE_SUCCESS = "ORDER_CREATE_SUCCESS";
export const ORDER_CREATE_ERROR = "ORDER_CREATE_ERROR";
export const ORDER_DELETE = "ORDER_DELETE";
export const ORDER_DELETE_SUCCESS = "ORDER_DELETE_SUCCESS";
export const ORDER_DELETE_ERROR = "ORDER_DELETE_ERROR";
export const ORDERS_LIST_FILTERED = "ORDERS_LIST_FILTERED";
export const ORDERS_LIST_FILTERED_SUCCESS = "ORDERS_LIST_FILTERED_SUCCESS";
export const ORDERS_LIST_FILTERED_ERROR = "ORDERS_LIST_FILTERED_ERROR";
export const ORDERS_DOWNLOAD_FILE = "ORDERS_DOWNLOAD_FILE";
export const ORDERS_DOWNLOAD_FILE_SUCCESS = "ORDERS_DOWNLOAD_FILE_SUCCESS";
export const ORDERS_DOWNLOAD_FILE_ERROR = "ORDERS_DOWNLOAD_FILE_ERROR";
