import { format } from 'date-fns';
import settings from '@/settings.js';
export default function viewDate (value) {
	try {
		let result = 'dd.mm.yyyy';
		if (value) {
			let datum = format(new Date(value), settings.viewDFormat);
			result = datum.toString();
		}
		return result;

	} catch (ex) {
		console.error(ex.toString());
		return 'dd.mm.yyyy';
	}
}
