import axios from 'axios';
import Handlers from './handlers'

const API_PATH = 'clients/';

class ClientsService {
    create(requestData) {
        const url = API_PATH + 'create';
        return new Promise((resolve, reject) => {
            axios.post(url, requestData)
                .then(response => {
                    // handle success
                    Handlers.SUCCESS_HANDLER(url, response.data, null, resolve);
                })
                .catch(error => {
                    // handle error
                    Handlers.ERROR_HANDLER(url, error, null, reject);
                });
        });
    }
    delete(client) {
        const url = API_PATH + 'delete';
        return new Promise((resolve, reject) => {
            axios.post(url, client.id)
                .then(response => {
                    // handle success
                    Handlers.SUCCESS_HANDLER(url, response.data, null, resolve);
                })
                .catch(error => {
                    // handle error
                    Handlers.ERROR_HANDLER(url, error, null, reject);
                });
        });
    }
	listFiltered(requestData) {
        const url = API_PATH + 'list_filtered';
        return new Promise((resolve, reject) => {
            axios.post(url, requestData)
                .then(response => {
                    // handle success
                    Handlers.SUCCESS_HANDLER(url, response.data, null, resolve);
                })
                .catch(error => {
                    // handle error
                    Handlers.ERROR_HANDLER(url, error, null, reject);
                });
        });
    }
    searchByTaxNumberFromInetis(requestData) {
        const url = API_PATH + 'search_by_tax_number_from_inetis';
        return new Promise((resolve, reject) => {
            axios.post(url, requestData)
                .then(response => {
                    // handle success
                    Handlers.SUCCESS_HANDLER(url, response.data, null, resolve);
                })
                .catch(error => {
                    // handle error
                    Handlers.ERROR_HANDLER(url, error, null, reject);
                });
        });
    }
    search(requestData) {
        const url = API_PATH + 'search';
        return new Promise((resolve, reject) => {
            axios.post(url, requestData)
                .then(response => {
                    // handle success
                    Handlers.SUCCESS_HANDLER(url, response.data, null, resolve);
                })
                .catch(error => {
                    // handle error
                    Handlers.ERROR_HANDLER(url, error, null, reject);
                });
        });
    }
    listAll(requestData) {
        const url = API_PATH + 'all';
        return new Promise((resolve, reject) => {
            axios.post(url, requestData)
                .then(response => {
                    // handle success
                    Handlers.SUCCESS_HANDLER(url, response.data, null, resolve);
                })
                .catch(error => {
                    // handle error
                    Handlers.ERROR_HANDLER(url, error, null, reject);
                });
        });
    }
}

export default new ClientsService();