export const EBILL_CREATE = "EBILL_CREATE";
export const EBILL_CREATE_SUCCESS = "EBILL_CREATE_SUCCESS";
export const EBILL_CREATE_ERROR = "EBILL_CREATE_ERROR";
export const EBILLS_LIST_FILTERED = "EBILLS_LIST_FILTERED";
export const EBILLS_LIST_FILTERED_SUCCESS = "EBILLS_LIST_FILTERED_SUCCESS";
export const EBILLS_LIST_FILTERED_ERROR = "EBILLS_LIST_FILTERED_ERROR";
export const EBILLS_DOWNLOAD_FILE = "EBILLS_DOWNLOAD_FILE";
export const EBILLS_DOWNLOAD_FILE_SUCCESS = "EBILLS_DOWNLOAD_FILE_SUCCESS";
export const EBILLS_DOWNLOAD_FILE_ERROR = "EBILLS_DOWNLOAD_FILE_ERROR";
export const EBILL_ARCHIVE = "EBILL_ARCHIVE";
export const EBILL_ARCHIVE_SUCCESS = "EBILL_ARCHIVE_SUCCESS";
export const EBILL_ARCHIVE_ERROR = "EBILL_ARCHIVE_ERROR";
export const ENVELOPE_GET = "ENVELOPE_GET";
export const ENVELOPE_GET_SUCCESS = "ENVELOPE_GET_SUCCESS";
export const ENVELOPE_GET_ERROR = "ENVELOPE_GET_ERROR";
export const EBILL_PREPARE = "EBILL_PREPARE";
export const EBILL_PREPARE_SUCCESS = "EBILL_PREPARE_SUCCESS";
export const EBILL_PREPARE_ERROR = "EBILL_PREPARE_ERROR";
export const EBILL_REJECT = "EBILL_REJECT";
export const EBILL_REJECT_SUCCESS = "EBILL_REJECT_SUCCESS";
export const EBILL_REJECT_ERROR = "EBILL_REJECT_ERROR";
export const EBILL_SEND_HALCOM = "EBILL_SEND_HALCOM";
export const EBILL_SEND_HALCOM_SUCCESS = "EBILL_SEND_HALCOM_SUCCESS";
export const EBILL_SEND_HALCOM_ERROR = "EBILL_SEND_HALCOM_ERROR";
export const CHANGE_CLIENT = "CHANGE_CLIENT";
export const CHANGE_CLIENT_SUCCESS = "CHANGE_CLIENT_SUCCESS";
export const CHANGE_CLIENT_ERROR = "CHANGE_CLIENT_ERROR";
export const CHANGE_ORDER = "CHANGE_ORDER";
export const CHANGE_ORDER_SUCCESS = "CHANGE_ORDER_SUCCESS";
export const CHANGE_ORDER_ERROR = "CHANGE_ORDER_ERROR";
export const EBILL_UNARCHIVE = "EBILL_UNARCHIVE";
export const EBILL_UNARCHIVE_SUCCESS = "EBILL_UNARCHIVE_SUCCESS";
export const EBILL_UNARCHIVE_ERROR = "EBILL_UNARCHIVE_ERROR";
export const EBILL_DELETE = "EBILL_DELETE";
export const EBILL_DELETE_SUCCESS = "EBILL_DELETE_SUCCESS";
export const EBILL_DELETE_ERROR = "EBILL_DELETE_ERROR";