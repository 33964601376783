<template>
	<b-modal :no-close-on-backdrop="true" :hide-header-close="isLoading" ref="modal" :title="title" :size="modalSize" class="modal-primary" @hidden="clearModalData">
		<CRow>
			<CCol>
				<CInput
					label="Številka naročilnice"
					v-model="order.numOrder"/>
			</CCol>
			<CCol>
				<CSelect
					label="Tip:"
					:options="settings.orderTypeOptions"
					:value.sync="order.type"
					custom
					@change="change_OrderType"
				/>
			</CCol>
		</CRow>
		<CRow v-if="order.type === settings.orderTypeOptions[1].value">
			<CCol col=6>
				<v-date-picker
					locale="sl-SI"
					v-model="order.validFrom"
					:model-config=" {
						type: 'string',
					}"
					timezone='utc'>
					<template v-slot="{ inputValue, inputEvents }">
						<CInput
							label="Veljavnost od"
							:value="inputValue"
							v-on="inputEvents"/>
					</template>
				</v-date-picker>
			</CCol>
			<CCol col=6>
				<v-date-picker
					locale="sl-SI"
					v-model="order.validUntil"
					:model-config=" {
						type: 'string',
					}"
					timezone='utc'>
					<template v-slot="{ inputValue, inputEvents }">
						<CInput
							label="Veljavnost od"
							:value="inputValue"
							v-on="inputEvents"/>
					</template>
				</v-date-picker>
			</CCol>
		</CRow>
		<CRow>
			<CCol>
				<CTextarea
					label="Opis"
					rows="3"
					v-model="order.description"/>
			</CCol>
		</CRow>
		<CRow>
			<CCol>
				<b-card header="Priloge">
					<CRow>
						<b-form-file
							ref="prilogeInput"
							multiple
							v-model="tmpOrderAttachments"
							:placeholder="uploadPlaceholder"
							:file-name-formatter="placeholderText"
							drop-placeholder="Povleci datoteko..."
							browse-text="Brskaj"
							@input="uploadOrderAttachments"
							accept=".pdf"/>
					</CRow>
					<CRow>
						<b-table
							v-if="orderAttachmentsToUpload.length > 0"
							class="mb-1"
							ref="uploadOrderAttachmentsTable"
							thead-class="hidden_header"
							striped
							hover
							:fields="orderAttachmentsToUploadTable_props.fields"
							:items="orderAttachmentsToUpload">
								<template v-slot:cell(progress)="data">
									<b-progress class="mt-1" :value="data.item.progress" :max="100" show-progress></b-progress>
								</template>
								<template v-slot:cell(file)="data">
									{{ data.item.name }}
								</template>
						</b-table>
					</CRow>
					<CRow v-if="orderAttachmentsToSave.length > 0">
						<b-table
							class="mb-1"
							ref="orderAttachmentsToSaveTable"
							thead-class="hidden_header"
							striped
							hover
							:fields="orderAttachmentsToSaveTable_props.fields"
							:items="orderAttachmentsToSave">
							<template v-slot:cell(actions)="data" >
								<div class="float-right">
									<b-button title="Odstrani" variant="danger" @click.stop="removeOrderAttachmentToSave_click(data.item)" size="sm">
										<CIcon name="cil-X"/>
									</b-button>
								</div>
							</template>
						</b-table>
					</CRow>
					<CRow>
						<b-table
							class="mb-1"
							v-if="orderAttachmentsRetrieved.length > 0 || mode == mode_EDIT2"
							ref="orderAttachmentsRetrievedTable"
							thead-class="hidden_header"
							striped
							hover
							show-empty
							empty-text="Ni prilog"
							:fields="orderAttachmentsRetrievedTable_props.fields"
							:items="orderAttachmentsRetrieved">
							<template v-slot:cell(type)="data">
								{{ data.item.type }}
							</template>
							<template v-slot:cell(actions)="data">
								<b-button title="Prenesi prilogo" variant="primary" class="mr-2" @click.stop="downloadOrderAttachment_click(data.item)" size="sm">
									<CIcon name="cil-vertical-align-bottom"/>
								</b-button>
								<b-button title="Odstrani" variant="danger" @click.stop="removeOrderAttachmentRetrieved_click(data.item)" size="sm">
									<CIcon name="cil-trash"/>
								</b-button>
							</template>
						</b-table>
					</CRow>
				</b-card>
			</CCol>
		</CRow>
		<CAlert v-show="message.text != null" :color="message.type">
			{{ message.text }}
		</CAlert>
		<template slot="modal-footer" slot-scope="{ cancel }">
			<b-container fluid class="p-0">
				<b-row>
					<b-col align-self="end" class="ma_pos">
						<b-button :disabled="isLoading" style="margin-right:5px;" variant="secondary" @click="cancel()">Prekliči</b-button>
						<b-button :disabled="okButton.disabled" variant="primary" @click.stop="onOkModal()">{{ okButton.label }}</b-button>
					</b-col>
				</b-row>
			</b-container>
		</template>
		<vue-loading :active.sync="isLoading" :is-full-page="false"/>
	</b-modal>
</template>

<script>
import axios from 'axios';
import settings from '@/settings.js';
import { addDays } from 'date-fns';
import { ORDER_CREATE } from "@/store/actions/orders";
import { FILE_REMOVE_UPLOADED, FILE_DOWNLOAD } from "@/store/actions/files";
const PH_TEXT = 'Izberi ali povleci datoteko ...';

export default {
	name: 'order-create-edit-modal',
	components: {
	},
	props: {
	},
	methods: {
		showModal: function (modalInput) {
			/*
				mode 1 => Create
				mode 2 => Edit
				mode 3 => Create with numOrder param
			*/
			this.mode = modalInput.mode;
			this.initModal(modalInput.order, modalInput.clientId);
		},
		initModal: function (order, clientId) {
			this.order.clientId = clientId;
			if (this.mode === this.mode_CREATE1) {
				this.title = 'Dodajanje naročilnice';
				this.okButton.label = 'Dodaj';
			} else if (this.mode === this.mode_EDIT2) {
				this.title = 'Urejanje naročilnice # ' + order.id;
				this.initOrder(order);
				this.okButton.label = 'Shrani';
			} else if (this.mode === this.mode_CREATEWITHNUMORDER3) {
				this.title = 'Dodajanje naročilnice';
				this.order.numOrder = order;
				this.okButton.label = 'Dodaj';
			} else {
				this.mode = this.mode_UNKNOWN0;
				this.title = 'Neznan način';
				this.okButton.label = 'Zapri';
			}
			this.$refs.modal.show();
		},
		initOrder: function (order) {
			this.order.id = order.id;
			this.order.numOrder = order.num_order;
			this.order.validFrom = order.valid_from;
			this.order.validUntil = order.valid_until;
			this.order.description = order.description;
			if (settings.orderTypeOptions.find((item) => item.value === order.type)) {
				this.order.type = order.type;
			} else {
				this.showAlert('Izbran tip "' + order.type + '" več ni mogoč, zato je nastavljen na privzeto vrednost.', this.ALERT_TYPE_ERROR, 5);
				this.order.type = settings.orderTypeOptions[0].value;
			}
			this.orderAttachmentsRetrieved = order.order_attachments;
		},
		onOkModal: function () {
			this.startProgress();
			if (this.order.type == 'multiple' && (!this.order.validFrom || !this.order.validUntil)) {
				this.showAlert('Izberite veljavnost naročilnice', this.ALERT_TYPE_ERROR, 3);
				this.stopProgress();
				return false;
			}
			this.$store.dispatch(ORDER_CREATE, { order: this.order, orderAttachments: this.orderAttachmentsToSave, orderAttachmentsToRemove: this.orderAttachmentsToRemove })
				.then((responseData) => {
					this.stopProgress();
					this.$emit('emit_orderCreatedOrEdited', responseData);
					this.orderAttachmentsToSave = [];
					this.$refs.modal.hide();
				})
				.catch(error => {
					this.stopProgress();
					console.error(error);
					if(error.response && error.response.data && error.response.data.code) {
						if (this.mode === this.mode_CREATE1) {
							this.showAlert('Napaka pri dodajanju! (' + error.response.data.code + ')', this.ALERT_TYPE_ERROR);
						} else if (this.mode === this.mode_EDIT2) {
							this.showAlert('Napaka pri urejanju! (' + error.response.data.code + ')', this.ALERT_TYPE_ERROR);
						} else {
							this.showAlert('Neznan način modala! (' + error.response.data.code + ')', this.ALERT_TYPE_ERROR);
						}
					} else {
						this.showAlert('Napaka! Prosimo poizkusite kasneje.', this.ALERT_TYPE_ERROR);
					}
				});
		},
		clearModalData: function () {
			this.isLoading = false;
			this.title = '';
			this.okButton = {
				label: 'Shrani',
				progress: false,
				disabled: false
			};
			this.showAlert();
			this.mode = 0;
			this.resetOrderObject();
			this.tmpOrderAttachments = null;
			this.orderAttachmentsToUpload = [];
			for(let orderAttachmentToSave of this.orderAttachmentsToSave) {
				this.$store.dispatch(FILE_REMOVE_UPLOADED, { filepath: orderAttachmentToSave.filepath })
					.then((responseData) => {
						this.stopProgress();
						this.orderAttachmentsToSave = this.orderAttachmentsToSave.filter(function(obj) {
							return obj.filepath !== responseData.filepath;
						});
					})
					.catch(error => {
						this.stopProgress();
						console.error(error);
						if(error.response && error.response.data && error.response.data.code) {
							this.showAlert('Napaka pri odstranjevanju datoteke! (' + error.response.data.code + ')', this.ALERT_TYPE_ERROR);
						} else {
							this.showAlert('Napaka! Prosimo poizkusite kasneje.', this.ALERT_TYPE_ERROR);
						}
					});
			}
			this.orderAttachmentsToSave = [];
			this.orderAttachmentsRetrieved = [];
			this.orderAttachmentsToRemove = [];
		},
		resetOrderObject: function () {
			this.order.id = null;
			this.order.clientId = null;
			this.order.numOrder = '';
			this.order.type = settings.orderTypeOptions[0].value;
			this.order.validFrom = null;
			this.order.validUntil = null;
			this.order.description = '';
		},
		startProgress: function () {
			this.isLoading = true;
			this.okButton.disabled = true;
			this.okButton.progress = true;
		},
		stopProgress: function () {
			this.isLoading = false;
			this.okButton.disabled = false;
			this.okButton.progress = false;
		},
		showAlert: function (message, type, seconds) {
			if (message && type && seconds) {
				this.message.type = type;
				this.message.text = message;
				setTimeout(() => {
					this.showAlert();
				}, seconds * 1000);
			} else if (message && type) {
				this.message.type = type;
				this.message.text = message;
			} else if (message) {
				this.message.type = 'primary';
				this.message.text = message;
			} else {
				this.message.type = null;
				this.message.text = null;
			}
		},
		change_OrderType: function () {
			if (this.order.type === settings.orderTypeOptions[1].value) { // multiple
				let today = new Date();
				today.setUTCHours(0,0,0,0);
				this.order.validFrom = today.toISOString();
				this.order.validUntil = addDays(today, 7).toISOString();
			} else {
				this.order.validFrom = null;
				this.order.validUntil = null;
			}
		},
		placeholderText: function () {
			return PH_TEXT;
		},
		uploadOrderAttachments: function () {
			for (let attachment of this.tmpOrderAttachments) {
				if (attachment.size > 10485760) {
					this.showError('Datoteka ' + attachment.name + ' je prevelika!')
				} else {
					this.orderAttachmentsToUpload.push(attachment);
					let response = this.uploadFile(this, attachment, 'orderAttachmentFile');
				}
			}
		},
		uploadFile: function (ctx, file) {
			let args = new FormData();
			let settings = {
				headers: {
					'content-type': 'multipart/form-data'
				},
				onUploadProgress: function(progressEvent) {
					let loadedPrecent = progressEvent.loaded / progressEvent.total * 100;
					let orderAttachment = ctx.orderAttachmentsToUpload.find(pril => pril.name === file.name);
					orderAttachment.progress = loadedPrecent;
					ctx.$refs.uploadOrderAttachmentsTable.refresh();
				}
			};
			args.append('file', file);
			return axios.post('/files/upload', args, settings)
				.then(response => {
					let attachment = {
						orginal_filename: response.data.orginal_filename,
						server_side_filename: response.data.server_side_filename,
						filepath: response.data.filepath,
						type: response.data.type,
					}
					this.orderAttachmentsToUpload = this.orderAttachmentsToUpload.filter(function(obj) {
						return obj.name !== attachment.orginal_filename;
					});
					this.orderAttachmentsToSave.push(attachment);
					return response.data;
				})
				.catch(error => {
					if (error) {
						console.log(error);
					}
				});
		},
		addFilesTypes: function (files) {
			for (let file of files) {
				let extension = file.name.substring(file.name.length - 4, file.name.length).toLowerCase();
				if (extension === '.pdf') {
					file.file_type = 'fa-file-pdf-o';
				} else if (extension === '.jpg' || extension === 'jpeg' || extension === '.bmp' || extension === '.png') {
					file.file_type = 'fa-file-image-o';
				} else if (extension === '.xls' || extension === 'xlsx' || extension === '.csv' || extension === '.slk') {
					file.file_type = 'fa-file-excel-o';
				} else if (extension === '.doc' || extension === 'docx') {
					file.file_type = 'fa-file-word-o';
				} else if (extension === '.txt') {
					file.file_type = 'fa-file-text-o';
				} else if (extension === '.ppt' || extension === 'pptx') {
					file.file_type = 'fa-file-powerpoint-o';
				} else {
					file.file_type = 'fa-file-o';
				}
			}
			return files;
		},
		removeOrderAttachmentToSave_click: function (item) {
			console.log('removeOrderAttachmentToSave_click', item);
			this.startProgress();
			this.$store.dispatch(FILE_REMOVE_UPLOADED, { filepath: item.filepath })
				.then((responseData) => {
					this.stopProgress();
					this.orderAttachmentsToSave = this.orderAttachmentsToSave.filter(function(obj) {
						return obj.filepath !== responseData.filepath;
					});
				})
				.catch(error => {
					this.stopProgress();
					console.error(error);
					if(error.response && error.response.data && error.response.data.code) {
						this.showAlert('Napaka pri odstranjevanju datoteke! (' + error.response.data.code + ')', this.ALERT_TYPE_ERROR);
					} else {
						this.showAlert('Napaka! Prosimo poizkusite kasneje.', this.ALERT_TYPE_ERROR);
					}
				});
		},
		removeOrderAttachmentRetrieved_click: function (item) {
			console.log('removeOrderAttachmentRetrieved_click');
			this.orderAttachmentsToRemove.push(item);
			this.orderAttachmentsRetrieved = this.orderAttachmentsRetrieved.filter(function (value) {
				return value.id !== item.id;
			});
		},
		downloadOrderAttachment_click: function (item) {
			this.startProgress();
			return this.$store.dispatch(FILE_DOWNLOAD, { filepath: item.filepath })
				.then((response) => {
					this.stopProgress();
					var newBlob = new Blob([response.data], {type: response.headers['content-type']});
					const data = window.URL.createObjectURL(newBlob);
					var link = document.createElement('a');
					link.href = data;
					link.download = item.orginal_filename;
					link.click();
					setTimeout(function () {
						// For Firefox it is necessary to delay revoking the ObjectURL
						window.URL.revokeObjectURL(data)
					}, 100);
				})
				.catch(error => {
					this.stopProgress();
					console.log(error);
					this.showAlert('Napaka! Prosimo poizkusite kasneje.', this.ALERT_TYPE_ERROR);
				});
		}
	},
	data: function () {
		return {
			settings,
			isLoading: false,
			title: '',
			okButton: {
				label: 'Shrani',
				progress: false,
				disabled: false
			},
			message: {
				type: null,
				text: null
			},
			ALERT_TYPE_SUCCESS: 'success',
			ALERT_TYPE_ERROR: 'danger',
			mode_UNKNOWN0: 0,
			mode_CREATE1: 1,
			mode_EDIT2: 2,
			mode_CREATEWITHNUMORDER3: 3,
			mode: 0,
			order: {
				id: null,
				clientId: null,
				numOrder: '',
				type: settings.orderTypeOptions[0].value,
				validFrom: null,
				validUntil: null,
				description: '',
			},
			tmpOrderAttachments: null,
			orderAttachmentsToUpload: [],
			orderAttachmentsToUploadTable_props: {
				fields: [
					{ key: 'file', label: '', sortable: false },
					{ key: 'progress', label: '', sortable: false, class: 'orderAttachmentsTdProgress'  }
				],
			},
			orderAttachmentsToSaveTable_props: {
				fields: [
					{ key: 'orginal_filename', label: '', sortable: false },
					{ key: 'actions', label: '', sortable: false, class: 'orderAttachmentsTdAcitons' }
				],
			},
			orderAttachmentsToSave: [],
			orderAttachmentsRetrievedTable_props: {
				fields: [
					{ key: 'orginal_filename', label: '', sortable: false },
					{ key: 'actions', label: '', sortable: false, class: 'orderAttachmentsTdAcitons' }
				],
			},
			orderAttachmentsRetrieved: [],
			orderAttachmentsToRemove: []
		}
	},
	watch: {
	},
	computed: {
		modalSize () {
			return 'md';
		},
		uploadPlaceholder () {
			return PH_TEXT;
		},
	},
	created () {
	}
}
</script>

<style>
.gdt_border {
	border: 1px solid #c2cfd6!important;
}
.orderAttachmentsTdAcitons {
	width: 100px;
	max-width: 100px;
}
.orderAttachmentsTdProgress {
	width: 150px;
	max-width: 150px;
}
</style>
<style scoped>
.ma_pos {
	text-align: end;
}
.dt_border {
	border: 1px solid #c2cfd6!important;
}
</style>