import {
	USER_CREATE_EDIT,
	USER_CREATE_EDIT_ERROR,
	USER_CREATE_EDIT_SUCCESS,
	USER_DETAILS,
	USER_DETAILS_ERROR,
	USER_DETAILS_SUCCESS,
	USER_DELETE,
	USER_DELETE_ERROR,
	USER_DELETE_SUCCESS,
	USER_CHANGE_PASSWORD,
	USER_CHANGE_PASSWORD_ERROR,
	USER_CHANGE_PASSWORD_SUCCESS,
	USER_CHANGE_PROFILE_NAME,
	USER_CHANGE_PROFILE_NAME_ERROR,
	USER_CHANGE_PROFILE_NAME_SUCCESS,
	USER_CHANGE_AVATAR,
	USER_CHANGE_AVATAR_ERROR,
	USER_CHANGE_AVATAR_SUCCESS,
	USERS_LIST,
	USERS_LIST_SUCCESS,
	USERS_LIST_ERROR,
	USERS_LIST_FILTERED,
	USERS_LIST_FILTERED_SUCCESS,
	USERS_LIST_FILTERED_ERROR
} from "../actions/users";
import {
	MENU_ITEMS
} from "../actions/roles";
import { AUTH_LOGOUT } from "../actions/auth";
import Vue from "vue";
import UsersService from '@/services/users';

const state = {
	status: '',
	profile: {},
	users: []
};

const getters = {
	getProfile: state => state.profile,
	isProfileLoaded: state => !!state.profile.name,
	getUsers: state => state.users
};

const actions = {
	[USER_CREATE_EDIT]: ({ commit, dispatch }, data) => {
		let requestData = {
			id: data.modalUser.id,
			name: data.modalUser.name,
			email: data.modalUser.email,
			password: data.modalUser.password,
			password_confirmation: data.modalUser.passwordConfirmation,
			role: data.modalUser.role,
			permissions: data.modalUser.permissions
		}
		return new Promise((resolve, reject) => {
			commit(USER_CREATE_EDIT);
			UsersService.createEdit(requestData).then(responseData => {
				commit(USER_CREATE_EDIT_SUCCESS);
				dispatch(USER_DETAILS);
				resolve(responseData);
			})
			.catch(error => {
				commit(USER_CREATE_EDIT_ERROR, error);
				dispatch(USER_DETAILS);
				reject(error);
			});
		});
	},
	[USER_DETAILS]: ({ commit, dispatch }) => {
		return new Promise((resolve, reject) => {
			commit(USER_DETAILS);
			UsersService.details().then(details => {
				window.Laravel = {
					roles: details.roles,
					permissions: details.permissions
				};
				commit(USER_DETAILS_SUCCESS, details);
				dispatch(MENU_ITEMS)
				resolve(details);
			})
			.catch(error => {
				commit(USER_DETAILS_ERROR, error);
				reject(error);
			});
		});
	},
	[USER_DELETE]: ({ commit, dispatch }, id) => {
		let requestData = {
			id: id,
		}
		return new Promise((resolve, reject) => {
			commit(USER_DELETE);
			UsersService.delete(requestData)
				.then(data => {
					commit(USER_DELETE_SUCCESS);
					resolve(data);
				})
				.catch(error => {
					commit(USER_DELETE_ERROR, error);
					reject(error);
				});
		});
	},
	[USERS_LIST]: ({ commit }) => {
		return new Promise((resolve, reject) => {
			commit(USERS_LIST);
			UsersService.list().then(users => {
				commit(USERS_LIST_SUCCESS, users);
				resolve(users);
			})
			.catch(error => {
				commit(USERS_LIST_ERROR, error);
				reject(error);
			});
		});
	},
	[USER_CHANGE_PASSWORD]: ({ commit }, data) => {
		let requestData = {
			password: data.formData.password,
			new_password: data.formData.newPassword,
			new_password_confirmation: data.formData.newPasswordConfirmation,
		}
		return new Promise((resolve, reject) => {
			commit(USER_CHANGE_PASSWORD);
			UsersService.changePassword(requestData).then(responseData => {
				commit(USER_CHANGE_PASSWORD_SUCCESS);
				resolve(responseData);
			})
			.catch(error => {
				commit(USER_CHANGE_PASSWORD_ERROR, error);
				reject(error);
			});
		});
	},
	[USER_CHANGE_PROFILE_NAME]: ({ commit, dispatch }, data) => {
		let requestData = {
			name: data.name
		}
		return new Promise((resolve, reject) => {
			commit(USER_CHANGE_PROFILE_NAME);
			UsersService.changeProfileName(requestData).then(responseData => {
				commit(USER_CHANGE_PROFILE_NAME_SUCCESS);
				dispatch(USER_DETAILS);
				resolve(responseData);
			})
			.catch(error => {
				commit(USER_CHANGE_PROFILE_NAME_ERROR, error);
				reject(error);
			});
		});
	},
	[USER_CHANGE_AVATAR]: ({ commit, dispatch }, data) => {
		let requestData = null;
		if (data.imageData) {
			requestData = new FormData();
			requestData.append('avatar', data.imageData);
		}
		return new Promise((resolve, reject) => {
			commit(USER_CHANGE_AVATAR);
			UsersService.changeAvatar(requestData).then(responseData => {
				commit(USER_CHANGE_AVATAR_SUCCESS);
				dispatch(USER_DETAILS);
				resolve(responseData);
			})
			.catch(error => {
				commit(USER_CHANGE_AVATAR_ERROR, error);
				reject(error);
			});
		});
	},
	[USERS_LIST_FILTERED]: ({ commit }, data) => {
		let requestData = {
			size: data.tableProps.itemsPerPage,
			page: data.tableProps.currentPage,
			sort: data.ctx.sortBy,
			desc: data.ctx.sortDesc,
		}
		if (data.tableProps.inputFilter.email) {
			requestData.emailFilter = data.tableProps.inputFilter.email;
		}
		if (data.tableProps.inputFilter.name) {
			requestData.nameFilter = data.tableProps.inputFilter.name;
		}
		return new Promise((resolve, reject) => {
			commit(USERS_LIST_FILTERED);
			UsersService.listFiltered(requestData)
				.then(responseData => {
					commit(USERS_LIST_FILTERED_SUCCESS, responseData);
					resolve(responseData);
				})
				.catch(error => {
					commit(USERS_LIST_FILTERED_ERROR, error);
					reject(error);
				});
		});
	}
};

const mutations = {
	[USER_CREATE_EDIT]: state => {
		state.status = 'loading';
	},
	[USER_CREATE_EDIT_SUCCESS]: (state) => {
		state.status = 'success';
	},
	[USER_CREATE_EDIT_ERROR]: state => {
		state.status = 'error';
	},
	[USER_DETAILS]: state => {
		state.status = 'loading';
	},
	[USER_DETAILS_SUCCESS]: (state, details) => {
		state.status = 'success';
		Vue.set(state, 'profile', details);
	},
	[USER_DETAILS_ERROR]: state => {
		state.status = 'error';
	},
	[USER_DELETE]: state => {
		state.status = 'loading';
	},
	[USER_DELETE_SUCCESS]: (state) => {
		state.status = 'success';
	},
	[USER_DELETE_ERROR]: state => {
		state.status = 'error';
	},
	[AUTH_LOGOUT]: state => {
		Vue.set(state, 'profile', {});
	},
	[USERS_LIST]: state => {
		state.status = 'loading';
	},
	[USERS_LIST_SUCCESS]: (state, users) => {
		state.status = 'success';
		Vue.set(state, 'users', users);
	},
	[USERS_LIST_ERROR]: state => {
		state.status = 'error';
		Vue.set(state, 'users', null);
	},
	[USER_CHANGE_PASSWORD]: state => {
		state.status = 'loading';
	},
	[USER_CHANGE_PASSWORD_SUCCESS]: (state) => {
		state.status = 'success';
	},
	[USER_CHANGE_PASSWORD_ERROR]: state => {
		state.status = 'error';
	},
	[USER_CHANGE_PROFILE_NAME]: state => {
		state.status = 'loading';
	},
	[USER_CHANGE_PROFILE_NAME_SUCCESS]: (state,) => {
		state.status = 'success';
	},
	[USER_CHANGE_PROFILE_NAME_ERROR]: state => {
		state.status = 'error';
	},
	[USER_CHANGE_AVATAR]: state => {
		state.status = 'loading';
	},
	[USER_CHANGE_AVATAR_SUCCESS]: (state,) => {
		state.status = 'success';
	},
	[USER_CHANGE_AVATAR_ERROR]: state => {
		state.status = 'error';
	},
	[USERS_LIST_FILTERED]: state => {
		state.status = 'loading';
	},
	[USERS_LIST_FILTERED_SUCCESS]: (state, responseData) => {
		state.status = 'success';
	},
	[USERS_LIST_FILTERED_ERROR]: state => {
		state.status = 'error';
	},
};

export default {
	state,
	getters,
	actions,
	mutations
};