import {
    AUTH_LOGIN,
    AUTH_LOGIN_ERROR,
    AUTH_LOGIN_SUCCESS,
    AUTH_LOGOUT,
    AUTH_CLEAR
} from "../actions/auth";
import {
    USER_DETAILS
} from "../actions/users";
import AuthService from '@/services/auth';
import axios from 'axios';

const state = {
    token: localStorage.getItem('user-token') || '',
    status: ''
};

const getters = {
    isAuthenticated: state => !!state.token,
    authStatus: state => state.status
};

const actions = {
    [AUTH_LOGIN]: ({ commit, dispatch }, credentials) => {
        return new Promise((resolve, reject) => {
            commit(AUTH_LOGIN);
            AuthService.login(credentials).then(data => {
                const user_token = `${data.token_type} ${data.access_token}`;
                localStorage.setItem('user-token', user_token);
                axios.defaults.headers.common['Authorization'] = user_token;
                commit(AUTH_LOGIN_SUCCESS, user_token);
                dispatch(USER_DETAILS);
                resolve(data);
            })
            .catch(error => {
                localStorage.removeItem('user-token');
                commit(AUTH_LOGIN_ERROR, error);
                reject(error);
            });
        });
    },
    [AUTH_LOGOUT]: ({ commit }) => {
        return new Promise((resolve, reject) => {
            AuthService.logout().then(data => {
                window.Laravel = null;
                localStorage.removeItem('user-token');
                delete axios.defaults.headers.common['Authorization'];
                commit(AUTH_LOGOUT);
                resolve(data);
            })
            .catch(error => {
                window.Laravel = null;
                localStorage.removeItem('user-token');
                delete axios.defaults.headers.common['Authorization'];
                commit(AUTH_LOGOUT);
                reject(error);
            });
        });
    },
    [AUTH_CLEAR]: ({ commit }) => {
        return new Promise(resolve => {
            window.Laravel = null;
            localStorage.removeItem('user-token');
            delete axios.defaults.headers.common['Authorization'];
            commit(AUTH_CLEAR);
            resolve();
        });
    }
};

const mutations = {
    [AUTH_LOGIN]: state => {
        state.status = 'loading';
    },
    [AUTH_LOGIN_SUCCESS]: (state, token) => {
        state.status = 'success';
        state.token = token;
    },
    [AUTH_LOGIN_ERROR]: state => {
        state.status = 'error';
    },
    [AUTH_LOGOUT]: state => {
        state.token = '';
    },
    [AUTH_CLEAR]: state => {
        state.token = '';
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};