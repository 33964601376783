<script>
import settings from '@/settings.js';
import PermissionCheck from '@/tools/permission-check';

export default {
	name: 'EbillAreaFilter',
	methods: {
		click_item (item) {
			this.allSelected = false;
			this.selectItem(item.value);
			this.$emit('change', this.selectedItems);
		},
		click_all (evt) {
			this.selectItem();
			this.allSelected = true;
			this.$emit('change', []);
		},
		selectItem (value) {
			this.selectedItems = [];
			for (let item of this.items) {
				if (this.multiselect) {
					if (value) {
						if (value === item.value) {
							item.active = !item.active;
						}
					} else {
						item.active = false;
					}
				} else {
					if (value) {
						if (value === item.value) {
							item.active = true;
						} else {
							item.active = false;
						}
					} else {
						item.active = false;
					}
				}
				if (item.active) {
					this.selectedItems.push(item);
				}
			}
			if (this.selectedItems.length === 0) {
				this.allSelected = true;
			}
		},
		clear () {
			this.selectItem();
		}
	},
	computed: {
		selectedTitle () {
			if (this.selectedItems.length === 0) {
				return this.defaultItemLabel;
			} else if (this.selectedItems.length === 1) {
				return this.selectedItems[0].label;
			} else {
				return this.selectedItems[0].label + ' ...';
			}
		},
		profile () {
			return this.$store.getters.getProfile;
		},
		items () {
			this.profile.permissions;
			let areas = [];
			for(let area of settings.ebillAreaOptions) {
				if (area.value === 'CDP') {
					if (PermissionCheck.can('CDP-read')) {
						areas.push(area);
					}
				} else if (area.value === 'Toyota') {
					if (PermissionCheck.can('Toyota-read')) {
						areas.push(area);
					}
				} else if (area.value === 'Form.NET') {
					if (PermissionCheck.can('Form.NET-read')) {
						areas.push(area);
					}
				} else if (area.value === 'Vasco') {
					if (PermissionCheck.can('Vasco-read')) {
						areas.push(area);
					}
				}
			}
			return areas;
		}
	},
	data: () => {
		return {
			defaultItemLabel: 'Vse',
			selectedItems: [],
			multiselect: true,
			allSelected: true
		}
	}
}
</script>
<template>
<b-dropdown :text="selectedTitle" ref="dropdown">
	<b-dropdown-header>Področje</b-dropdown-header>
	<b-dropdown-item @click="click_item(item)" v-for="item in items" :key="item.value" :active="item.active">
		{{ item.label }}
	</b-dropdown-item>
	<b-dropdown-divider></b-dropdown-divider>
	<b-dropdown-item @click="click_all" :active="allSelected">{{ defaultItemLabel }}</b-dropdown-item>
</b-dropdown>
</template>
<style>
</style>