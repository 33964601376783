export const USER_CREATE_EDIT = "USER_CREATE_EDIT";
export const USER_CREATE_EDIT_SUCCESS = "USER_CREATE_EDIT_SUCCESS";
export const USER_CREATE_EDIT_ERROR = "USER_CREATE_EDIT_ERROR";
export const USER_DETAILS = "USER_DETAILS";
export const USER_DETAILS_SUCCESS = "USER_DETAILS_SUCCESS";
export const USER_DETAILS_ERROR = "USER_DETAILS_ERROR";
export const USER_DELETE = "USER_DELETE";
export const USER_DELETE_SUCCESS = "USER_DELETE_SUCCESS";
export const USER_DELETE_ERROR = "USER_DELETE_ERROR";
export const USER_CHANGE_PASSWORD = "USER_CHANGE_PASSWORD";
export const USER_CHANGE_PASSWORD_SUCCESS = "USER_CHANGE_PASSWORD_SUCCESS";
export const USER_CHANGE_PASSWORD_ERROR = "USER_CHANGE_PASSWORD_ERROR";
export const USER_CHANGE_PROFILE_NAME = "USER_CHANGE_PROFILE_NAME";
export const USER_CHANGE_PROFILE_NAME_SUCCESS = "USER_CHANGE_PROFILE_NAME_SUCCESS";
export const USER_CHANGE_PROFILE_NAME_ERROR = "USER_CHANGE_PROFILE_NAME_ERROR";
export const USER_CHANGE_AVATAR = "USER_CHANGE_AVATAR";
export const USER_CHANGE_AVATAR_SUCCESS = "USER_CHANGE_AVATAR_SUCCESS";
export const USER_CHANGE_AVATAR_ERROR = "USER_CHANGE_AVATAR_ERROR";
export const USERS_LIST = "USERS_LIST";
export const USERS_LIST_SUCCESS = "USERS_LIST_SUCCESS";
export const USERS_LIST_ERROR = "USERS_LIST_ERROR";
export const USERS_LIST_FILTERED = "USERS_LIST_FILTERED";
export const USERS_LIST_FILTERED_SUCCESS = "USERS_LIST_FILTERED_SUCCESS";
export const USERS_LIST_FILTERED_ERROR = "USERS_LIST_FILTERED_ERROR";
