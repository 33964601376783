import axios from 'axios';
import Handlers from './handlers'

const API_PATH = 'files/';

class FilesService {
    removeUploaded(requestData) {
        const url = API_PATH + 'remove_uploaded';
        return new Promise((resolve, reject) => {
            axios.post(url, requestData)
                .then(response => {
                    // handle success
                    Handlers.SUCCESS_HANDLER(url, response.data, null, resolve);
                })
                .catch(error => {
                    // handle error
                    Handlers.ERROR_HANDLER(url, error, null, reject);
                });
        });
    }
	download(requestData) {
        const url = API_PATH + 'download';
		let headers = { 'responseType': 'arraybuffer' }
        return new Promise((resolve, reject) => {
            axios.post(url, requestData, headers)
                .then(response => {
                    // handle success
                    Handlers.SUCCESS_HANDLER(url, response, null, resolve);
                })
                .catch(error => {
                    // handle error
                    Handlers.ERROR_HANDLER(url, error, null, reject);
                });
        });
    }
	changeArchive(requestData) {
		const url = API_PATH + 'change_archive';
        return new Promise((resolve, reject) => {
            axios.post(url, requestData)
                .then(response => {
                    // handle success
                    Handlers.SUCCESS_HANDLER(url, response, null, resolve);
                })
                .catch(error => {
                    // handle error
                    Handlers.ERROR_HANDLER(url, error, null, reject);
                });
        });
	}
}

export default new FilesService();