import axios from 'axios';
import Handlers from './handlers'

const API_PATH = 'email/';

class EmailService {
	getTemplate(requestData) {
        const url = API_PATH + 'get_template';
        return new Promise((resolve, reject) => {
            axios.post(url, requestData)
                .then(response => {
                    // handle success
                    Handlers.SUCCESS_HANDLER(url, response.data, null, resolve);
                })
                .catch(error => {
                    // handle error
                    Handlers.ERROR_HANDLER(url, error, null, reject);
                });
        });
    }
	send(requestData) {
        const url = API_PATH + 'send';
        return new Promise((resolve, reject) => {
            axios.post(url, requestData)
                .then(response => {
                    // handle success
                    Handlers.SUCCESS_HANDLER(url, response.data, null, resolve);
                })
                .catch(error => {
                    // handle error
                    Handlers.ERROR_HANDLER(url, error, null, reject);
                });
        });
    }
}

export default new EmailService();