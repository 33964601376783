import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'
import PermissionCheck from '@/tools/permission-check';

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Pages
const Eracuni = () => import('@/views/pages/Eracuni')
const Clients = () => import('@/views/pages/Clients')
const Users = () => import('@/views/pages/Users')
const Profile = () => import('@/views/pages/Profile')
const Settings = () => import('@/views/pages/Settings')
const Login = () => import('@/views/pages/Login')
const Unauthorized = () => import('@/views/pages/Unauthorized')
const EnvelopeXMLpreview = () => import('@/views/pages/EnvelopeXMLpreview')

// Views
const Dashboard = () => import('@/views/Dashboard')

const Colors = () => import('@/views/theme/Colors')
const Typography = () => import('@/views/theme/Typography')

const Charts = () => import('@/views/charts/Charts')
const Widgets = () => import('@/views/widgets/Widgets')

// Views - Components
const Cards = () => import('@/views/base/Cards')
const Forms = () => import('@/views/base/Forms')
const Switches = () => import('@/views/base/Switches')
const Tables = () => import('@/views/base/Tables')
const Tabs = () => import('@/views/base/Tabs')
const Breadcrumbs = () => import('@/views/base/Breadcrumbs')
const Carousels = () => import('@/views/base/Carousels')
const Collapses = () => import('@/views/base/Collapses')
const Jumbotrons = () => import('@/views/base/Jumbotrons')
const ListGroups = () => import('@/views/base/ListGroups')
const Navs = () => import('@/views/base/Navs')
const Navbars = () => import('@/views/base/Navbars')
const Paginations = () => import('@/views/base/Paginations')
const Popovers = () => import('@/views/base/Popovers')
const ProgressBars = () => import('@/views/base/ProgressBars')
const Tooltips = () => import('@/views/base/Tooltips')

// Views - Buttons
const StandardButtons = () => import('@/views/buttons/StandardButtons')
const ButtonGroups = () => import('@/views/buttons/ButtonGroups')
const Dropdowns = () => import('@/views/buttons/Dropdowns')
const BrandButtons = () => import('@/views/buttons/BrandButtons')

// Views - Icons
const CoreUIIcons = () => import('@/views/icons/CoreUIIcons')
const Brands = () => import('@/views/icons/Brands')
const Flags = () => import('@/views/icons/Flags')

// Views - Notifications
const Alerts = () => import('@/views/notifications/Alerts')
const Badges = () => import('@/views/notifications/Badges')
const Modals = () => import('@/views/notifications/Modals')

// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Register = () => import('@/views/pages/Register')

// Users
const UsersCUI = () => import('@/views/users/Users')
const UserCUI = () => import('@/views/users/User')

function configRoutes () {
  return [
    {
      path: '/',
      redirect: '/dashboard',
      name: 'Domov',
      component: TheContainer,
      children: [
        {
          path: 'dashboard',
          name: 'Nadzorna plošča',
          component: Clients
        },
        {
          path: 'theme',
          redirect: '/theme/colors',
          name: 'Theme',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'colors',
              name: 'Colors',
              component: Colors
            },
            {
              path: 'typography',
              name: 'Typography',
              component: Typography
            }
          ]
        },
        {
          path: 'charts',
          name: 'Charts',
          component: Charts
        },
        {
          path: 'widgets',
          name: 'Widgets',
          component: Widgets
        },
        {
          path: 'users',
          meta: {
            label: 'Users'
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              name: 'Users',
              component: UsersCUI
            },
            {
              path: ':id',
              meta: {
                label: 'User Details'
              },
              name: 'User',
              component: UserCUI
            }
          ]
        },
        {
          path: 'base',
          redirect: '/base/cards',
          name: 'Base',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'cards',
              name: 'Cards',
              component: Cards
            },
            {
              path: 'forms',
              name: 'Forms',
              component: Forms
            },
            {
              path: 'switches',
              name: 'Switches',
              component: Switches
            },
            {
              path: 'tables',
              name: 'Tables',
              component: Tables
            },
            {
              path: 'tabs',
              name: 'Tabs',
              component: Tabs
            },
            {
              path: 'breadcrumbs',
              name: 'Breadcrumbs',
              component: Breadcrumbs
            },
            {
              path: 'carousels',
              name: 'Carousels',
              component: Carousels
            },
            {
              path: 'collapses',
              name: 'Collapses',
              component: Collapses
            },
            {
              path: 'jumbotrons',
              name: 'Jumbotrons',
              component: Jumbotrons
            },
            {
              path: 'list-groups',
              name: 'List Groups',
              component: ListGroups
            },
            {
              path: 'navs',
              name: 'Navs',
              component: Navs
            },
            {
              path: 'navbars',
              name: 'Navbars',
              component: Navbars
            },
            {
              path: 'paginations',
              name: 'Paginations',
              component: Paginations
            },
            {
              path: 'popovers',
              name: 'Popovers',
              component: Popovers
            },
            {
              path: 'progress-bars',
              name: 'Progress Bars',
              component: ProgressBars
            },
            {
              path: 'tooltips',
              name: 'Tooltips',
              component: Tooltips
            }
          ]
        },
        {
          path: 'buttons',
          redirect: '/buttons/standard-buttons',
          name: 'Buttons',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'standard-buttons',
              name: 'Standard Buttons',
              component: StandardButtons
            },
            {
              path: 'button-groups',
              name: 'Button Groups',
              component: ButtonGroups
            },
            {
              path: 'dropdowns',
              name: 'Dropdowns',
              component: Dropdowns
            },
            {
              path: 'brand-buttons',
              name: 'Brand Buttons',
              component: BrandButtons
            }
          ]
        },
        {
          path: 'icons',
          redirect: '/icons/coreui-icons',
          name: 'CoreUI Icons',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'coreui-icons',
              name: 'Icons library',
              component: CoreUIIcons
            },
            {
              path: 'brands',
              name: 'Brands',
              component: Brands
            },
            {
              path: 'flags',
              name: 'Flags',
              component: Flags
            }
          ]
        },
        {
          path: 'notifications',
          redirect: '/notifications/alerts',
          name: 'Notifications',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'alerts',
              name: 'Alerts',
              component: Alerts
            },
            {
              path: 'badges',
              name: 'Badges',
              component: Badges
            },
            {
              path: 'modals',
              name: 'Modals',
              component: Modals
            }
          ]
        },
      ],
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/pages',
      redirect: '/pages/404',
      name: 'Pages',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: '404',
          name: 'Page404',
          component: Page404
        },
        {
          path: '500',
          name: 'Page500',
          component: Page500
        },
        {
          path: 'register',
          name: 'Register',
          component: Register
        }
      ],
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/eracuni',
      redirect: '/eracuni/list',
      name: 'eRačuni',
      component: TheContainer,
      children: [
        {
          path: 'list',
          name: 'Seznam eRačunov',
          component: Eracuni
        },
      ],
      meta: {
        requiresAuth: true
      }
    },
	{
		path: '/envelope_xml_preview',
		name: 'EnvelopeXMLpreview',
		component: EnvelopeXMLpreview,
		meta: {
		  requiresAuth: true
		}
	},
    {
      path: '/entities',
      redirect: '/entities/clients',
      name: 'Šifranti',
      component: TheContainer,
      children: [
        {
          path: 'clients',
          redirect: 'clients/list',
          name: 'Kupci',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'list',
              name: 'Seznam kupcev',
              component: Clients
            }
          ],
          meta: {
            permissions: ['clients-read', 'clients-write']
          }
        },
        {
          path: 'users',
          redirect: 'users/list',
          name: 'Uporabniki',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'list',
              name: 'Seznam uporabnikov',
              component: Users,
              meta: {
                permissions: ['users-read']
              }
            },
            {
              path: 'profile',
              name: 'Uporabniški račun',
              component: Profile,
            },
            {
              path: 'settings',
              name: 'Nastavitve',
              component: Settings,
            }
          ]
        }
      ],
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/login',
      name: 'Prijava',
      component: Login,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/unauthorized',
      name: 'Not Authorized',
      component: Unauthorized,
    }
  ]
}

Vue.use(Router);

const router = new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth == true)) {
    if (store.getters.isAuthenticated) {
      // get route permissions
      const permissions = to.matched.reduce((permissions, record) => {
        if (record.meta && record.meta.permissions) {
          permissions = permissions.concat(record.meta.permissions);
        }
        return permissions;
      }, []);
      // check user permissions
      if (permissions.every((permission) => PermissionCheck.can(permission))) {
        next();
      } else {
        next('/unauthorized');
      }
    } else {
      next('/login');
    }
  } else if (to.matched.some(record => record.meta.requiresAuth == false)) {
    if (store.getters.isAuthenticated) {
      next('/dashboard');
    } else {
      next();
    }
  } else {
    next();
  }
})

export default router;
