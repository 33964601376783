export const CLIENT_CREATE = "CLIENT_CREATE";
export const CLIENT_CREATE_SUCCESS = "CLIENT_CREATE_SUCCESS";
export const CLIENT_CREATE_ERROR = "CLIENT_CREATE_ERROR";
export const CLIENT_DELETE = "CLIENT_DELETE";
export const CLIENT_DELETE_SUCCESS = "CLIENT_DELETE_SUCCESS";
export const CLIENT_DELETE_ERROR = "CLIENT_DELETE_ERROR";
export const CLIENTS_LIST_FILTERED = "CLIENTS_LIST_FILTERED";
export const CLIENTS_LIST_FILTERED_SUCCESS = "CLIENTS_LIST_FILTERED_SUCCESS";
export const CLIENTS_LIST_FILTERED_ERROR = "CLIENTS_LIST_FILTERED_ERROR";
export const CLIENT_SEARCH_BY_TAX_NUMBER_FROM_INETIS = "CLIENT_SEARCH_BY_TAX_NUMBER_FROM_INETIS";
export const CLIENT_SEARCH_BY_TAX_NUMBER_FROM_INETIS_SUCCESS = "CLIENT_SEARCH_BY_TAX_NUMBER_FROM_INETIS_SUCCESS";
export const CLIENT_SEARCH_BY_TAX_NUMBER_FROM_INETIS_ERROR = "CLIENT_SEARCH_BY_TAX_NUMBER_FROM_INETIS_ERROR";
export const CLIENT_SEARCH = "CLIENT_SEARCH";
export const CLIENT_SEARCH_SUCCESS = "CLIENT_SEARCH_SUCCESS";
export const CLIENT_SEARCH_ERROR = "CLIENT_SEARCH_ERROR";
export const CLIENTS_LIST_ALL = "CLIENTS_LIST_ALL";
export const CLIENTS_LIST_ALL_SUCCESS = "CLIENTS_LIST_ALL_SUCCESS";
export const CLIENTS_LIST_ALL_ERROR = "CLIENTS_LIST_ALL_ERROR";
