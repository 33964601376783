import Vue from 'vue';
import Vuex from 'vuex';
import users from "./modules/users";
import auth from "./modules/auth";
import coreui from "./modules/coreui";
import clients from "./modules/clients";
import roles from "./modules/roles";
import ebills from "./modules/ebills";
import email from "./modules/email";
import orders from "./modules/orders";
import files from "./modules/files";
import ebillsAttachments from "./modules/ebillsAttachments";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  modules: {
    users,
    auth,
    coreui,
	clients,
	roles,
	ebills,
	email,
	orders,
	files,
	ebillsAttachments
  },
  plugins: [createPersistedState({
    paths: ['users', 'coreui', 'roles'],
  })],
  strict: debug
});
