import {
	EMAIL_GET_TEMPLATE,
	EMAIL_GET_TEMPLATE_SUCCESS,
	EMAIL_GET_TEMPLATE_ERROR,
	EMAIL_SEND,
	EMAIL_SEND_SUCCESS,
	EMAIL_SEND_ERROR
} from "../actions/email";
import Vue from "vue";
import EmailService from '@/services/email';

const state = {
	status: ''
};

const getters = {
};

const actions = {
	[EMAIL_GET_TEMPLATE]: ({ commit }, data) => {
		let requestData = {
			emailType: data.emailType,
			emailRecievers: data.emailRecievers
		}
		return new Promise((resolve, reject) => {
			commit(EMAIL_GET_TEMPLATE);
			EmailService.getTemplate(requestData)
				.then(responseData => {
					commit(EMAIL_GET_TEMPLATE_SUCCESS, responseData);
					resolve(responseData);
				})
				.catch(error => {
					commit(EMAIL_GET_TEMPLATE_ERROR, error);
					reject(error);
				});
		});
	},
	[EMAIL_SEND]: ({ commit }, data) => {
		let requestData = {
			emailType: data.emailType,
			emailSubject: data.emailSubject,
			emailContent: data.emailContent,
			emailRecievers: data.emailRecievers,
			ebillId: data.ebillId,
		}
		return new Promise((resolve, reject) => {
			commit(EMAIL_SEND);
			EmailService.send(requestData)
				.then(responseData => {
					commit(EMAIL_SEND_SUCCESS, responseData);
					resolve(responseData);
				})
				.catch(error => {
					commit(EMAIL_SEND_ERROR, error);
					reject(error);
				});
		});
	}
};

const mutations = {
	[EMAIL_GET_TEMPLATE]: state => {
		state.status = 'loading';
	},
	[EMAIL_GET_TEMPLATE_SUCCESS]: (state, responseData) => {
		state.status = 'success';
	},
	[EMAIL_GET_TEMPLATE_ERROR]: state => {
		state.status = 'error';
	},
	[EMAIL_SEND]: state => {
		state.status = 'loading';
	},
	[EMAIL_SEND_SUCCESS]: (state, responseData) => {
		state.status = 'success';
	},
	[EMAIL_SEND_ERROR]: state => {
		state.status = 'error';
	},
};

export default {
	state,
	getters,
	actions,
	mutations
};