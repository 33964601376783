import axios from 'axios';
import Handlers from './handlers'

const API_PATH = 'auth/';

class AuthService {
    login(credentials) {
        const url = API_PATH + 'login';
        return new Promise((resolve, reject) => {
            axios.post(url, credentials)
                .then(response => {
                    // handle success
                    Handlers.SUCCESS_HANDLER(url, response.data, credentials, resolve);
                })
                .catch(error => {
                    // handle error
                    Handlers.ERROR_HANDLER(url, error, credentials, reject);
                });
        });
    }
    logout() {
        const url = API_PATH + 'logout';
        return new Promise((resolve, reject) => {
            axios.post(url)
                .then(response => {
                    // handle success
                    Handlers.SUCCESS_HANDLER(url, response.data, null, resolve);
                })
                .catch(error => {
                    // handle error
                    Handlers.ERROR_HANDLER(url, error, null, reject);
                });
        });
    }
}

export default new AuthService();