const permission_can = function(value) {
    if (!window.Laravel || !window.Laravel.permissions) {
        return false;
    }
    var permissions = window.Laravel.permissions;
    var _return = false;
    if (!Array.isArray(permissions)) {
        return false;
    }
    if (value.includes('|')) {
        value.split('|').forEach(function (item) {
            if (permissions.includes(item.trim())) {
                _return = true;
            }
        });
    } else if (value.includes('&')) {
        _return = true;
        value.split('&').forEach(function (item) {
            if (!permissions.includes(item.trim())) {
                _return = false;
            }
        });
    } else {
        _return = permissions.includes(value.trim());
    }
    return _return;
};
const permission_is = function(value) {
    if (!window.Laravel || !window.Laravel.roles) {
        return false;
    }
    var roles = window.Laravel.roles;
    var _return = false;
    if (!Array.isArray(roles)) {
        return false;
    }
    if (value.includes('|')) {
        value.split('|').forEach(function (item) {
            if (roles.includes(item.trim())) {
                _return = true;
            }
        });
    } else if (value.includes('&')) {
        _return = true;
        value.split('&').forEach(function (item) {
            if (!roles.includes(item.trim())) {
                _return = false;
            }
        });
    } else {
        _return = roles.includes(value.trim());
    }
    return _return;
}

export default {
    can: permission_can,
    is: permission_is
};