<template>
	<b-card>
		
		<vue-loading :active.sync="isLoading" :is-full-page="false"/>
		<b-row class="mb-4">
			<b-col v-if="!clientModal" sm=1 class="text-sm-right mt-1"><b>Stranka:</b></b-col>
			<b-col v-if="!clientModal && !editingDisabled" class="text-right mt-1">
				<CLink title="Uredi stranko" href="#" class="card-header-action btn-setting" @click.stop="editClient">
					<CIcon name="cil-pencil"/>
				</CLink>
			</b-col>
			<b-col v-if="!clientModal" sm=5 class="text-left">
				<v-select
					:disabled="editingDisabled"
					:options="allClients"
					v-model="ebill.client"
					@input="change_clientsSelect"
					placeholder="Izberi stranko ..."
					:filter-by="filter_clientsSelect"
					label="id">
					<template slot="no-options">Stranka ne obstaja</template>
					<template slot="option" slot-scope="option">
						{{ getClientSelectLabel(option )}}
					</template>
					<template slot="selected-option" slot-scope="option">
						{{ getClientSelectLabel(option )}}
					</template>
				</v-select>
			</b-col>
			<b-col :sm="clientModal ? 2 : 1" class="text-sm-right mt-1"><b>Naročilnica:</b></b-col>
			<b-col :sm="clientModal ? 8 : 4" class="text-sm-left mt-1" v-if="ebill.client == null">Najprej izberite stranko</b-col>
			<b-col sm=4 v-else-if="avaiableOrders == null">
				<b-spinner  variant="primary" small type="grow"/>
			</b-col>
			<b-col sm=4 v-else-if="avaiableOrders === false">
				Napaka pri pridobivanju naročilnic
			</b-col>
			<b-col :sm="clientModal ? 8 : 4" class="text-sm-left" v-else>
				<v-select
					:disabled="editingDisabled"
					:options="avaiableOrders"
					v-model="selectedOrder"
					@input="change_ordersSelect"
					placeholder="Izberi naročilnico ..."
					:filter-by="filter_ordersSelect"
					taggable
					label="num_order">
					<template slot="no-options">Naročilnica ne obstaja</template>
					<template slot="option" slot-scope="option">
						<div v-if="option.type == null">
							{{ option.num_order }}
						</div>
						<div v-if="option.type === 'single'">
							<b>{{ option.num_order }}</b> - Enkratna - {{ option.description }} 
							<b-badge class="mt-0" :variant="getNumOrderAttachments(option) > 0 ? 'success' : 'danger'" :title="getNumOrderAttachments(option) > 0 ? 'Priloga je dodana' : 'Priloga ni dodana'"> {{ getNumOrderAttachments(option) }} </b-badge>
						</div>
						<div v-if="option.type === 'multiple'">
							<b>{{ option.num_order }}</b> - Terminska ({{ option.valid_from | viewDate }} - {{ option.valid_until | viewDate }}) - {{ option.description }}
							<b-badge class="mt-0" :variant="getNumOrderAttachments(option) > 0 ? 'success' : 'danger'" :title="getNumOrderAttachments(option) > 0 ? 'Priloga je dodana' : 'Priloga ni dodana'"> {{ getNumOrderAttachments(option) }} </b-badge>
						</div>
					</template>
					<template slot="selected-option" slot-scope="option">
						<div v-if="option.type == null">
							{{ option.num_order }}
						</div>
						<div v-if="option.type === 'single'">
							<b>{{ option.num_order }}</b> - Enkratna - {{ option.description }} 
							<b-badge class="mt-0" :variant="getNumOrderAttachments(option) > 0 ? 'success' : 'danger'" :title="getNumOrderAttachments(option) > 0 ? 'Priloga je dodana' : 'Priloga ni dodana'"> {{ getNumOrderAttachments(option) }} </b-badge>
						</div>
						<div v-if="option.type === 'multiple'">
							<b>{{ option.num_order }}</b> - Terminska ({{ option.valid_from | viewDate }} - {{ option.valid_until | viewDate }}) - {{ option.description }}
							<b-badge class="mt-0" :variant="getNumOrderAttachments(option) > 0 ? 'success' : 'danger'" :title="getNumOrderAttachments(option) > 0 ? 'Priloga je dodana' : 'Priloga ni dodana'"> {{ getNumOrderAttachments(option) }} </b-badge>
						</div>
					</template>
				</v-select>
			</b-col>
			<b-col v-if="ebill.client != null  && !editingDisabled">
				<CLink title="Dodaj naročilnico" href="#" class="card-header-action btn-setting" @click.stop="change_ordersSelect">
					<CIcon name="cil-plus"/>
				</CLink>
			</b-col>
		</b-row>
		<b-row v-if="ebill.envelope != null">
			<b-col v-if="clientModal">
				<b-card header-bg-variant="dark" header-text-variant="white">
					<div slot="header">
						<b>Ovojnica</b>
					</div>
					<b-row class="mb-4">
						<b-col sm=2 class="text-sm-right"><b>Prejemnik:</b></b-col>
						<b-col> {{ ebill.envelope.receiver_name }}, {{ ebill.envelope.receiver_street }}, {{ ebill.envelope.receiver_city }} {{ ebill.envelope.receiver_country }} </b-col>
					</b-row>
					<b-row class="mb-4">
						<b-col sm=2 class="text-sm-right"><b>Davčna št.:</b></b-col>
						<b-col> {{ ebill.envelope.receiver_identifier }}</b-col>
						<b-col sm=2 class="text-sm-right"><b>TRR:</b></b-col>
						<b-col> {{ ebill.envelope.receiver_mailbox}} </b-col>
					</b-row>
					<b-row class="mb-4">
						<b-col sm=2 class="text-sm-right"><b>Email:</b></b-col>
						<b-col> {{ ebill.envelope.receiver_email_id}} </b-col>
						<b-col sm=2 class="text-sm-right"><b>Telefonska:</b></b-col>
						<b-col> {{ ebill.envelope.receiver_phone}} </b-col>
					</b-row>
					<b-row class="mb-4">
						<b-col sm=2 class="text-sm-right"><b>Koda namena:</b></b-col>
						<b-col> {{ ebill.envelope.purpose}} </b-col>
						<b-col sm=2 class="text-sm-right"><b>Rok plačila:</b></b-col>
						<b-col v-if="ebill.envelope.requested_execution_date"> {{ ebill.envelope.requested_execution_date | viewDate }}</b-col>
						<b-col v-else> / </b-col>
					</b-row>
				</b-card>
			</b-col>
			<b-col v-else>
				<b-card header-bg-variant="dark" header-text-variant="white">
					<div slot="header">
						<b>Ovojnica</b>
					</div>
					<b-row class="mb-4">
						<b-col sm=1 class="text-sm-right"><b>Prejemnik:</b></b-col>
						<b-col sm=5> {{ ebill.envelope.receiver_name }}, {{ ebill.envelope.receiver_street }}, {{ ebill.envelope.receiver_city }} {{ ebill.envelope.receiver_country }} </b-col>
						<b-col sm=1 class="text-sm-right"><b>Davčna št.:</b></b-col>
						<b-col> {{ ebill.envelope.receiver_identifier }}</b-col>
						<b-col sm=1 class="text-sm-right"><b>TRR:</b></b-col>
						<b-col> {{ ebill.envelope.receiver_mailbox}} </b-col>
					</b-row>
					<b-row class="mb-4">
						<b-col sm=1 class="text-sm-right"><b>Email:</b></b-col>
						<b-col> {{ ebill.envelope.receiver_email_id}} </b-col>
						<b-col sm=1 class="text-sm-right"><b>Telefonska:</b></b-col>
						<b-col> {{ ebill.envelope.receiver_phone}} </b-col>
						<b-col sm=1 class="text-sm-right"><b>Koda namena:</b></b-col>
						<b-col> {{ ebill.envelope.purpose}} </b-col>
						<b-col sm=1 class="text-sm-right"><b>Rok plačila:</b></b-col>
						<b-col v-if="ebill.envelope.requested_execution_date"> {{ ebill.envelope.requested_execution_date | viewDate }}</b-col>
						<b-col v-else> / </b-col>
					</b-row>
				</b-card>
			</b-col>
		</b-row>
		<b-row>
			<b-col>
				<b-card class="m-0" header-bg-variant="dark" header-text-variant="white">
					<div slot="header">
						<b>Priloge</b>
					</div>
					<div class="text-center" v-if="ebill.envelope == null && ebill.ebill_file == null && ebill.ebill_attachment.length < 1 && (!ebill.order || (ebill.order != null && !ebill.order.order_attachments))">
						<b>Ni prilog</b>
					</div>
					<div v-else>
						<b-table
							v-if="ebill.envelope != null"
							bordered
							small
							class="mb-0"
							thead-class="d-none"
							:items="[ebill.envelope]"
							:fields="filesTableProps.fields">
							<template v-slot:cell(actions)="data">
								<div class="float-right">
									<b-button :disabled="!data.item.filepath" class="mr-2" :title="!data.item.filepath ? 'Datoteka na strežniku ni najdena' : 'Predogled'" variant="primary" @click.stop="getFileFromServer(data.item, 'preview')" size="sm">
										<CIcon name="cil-zoom-in"/>
									</b-button>
									<b-button :disabled="!data.item.filepath" :title="!data.item.filepath ? 'Datoteka na strežniku ni najdena' : 'Prenesi prilogo'" variant="primary" @click.stop="getFileFromServer(data.item)" size="sm">
										<CIcon name="cil-vertical-align-bottom"/>
									</b-button>
								</div>
							</template>
							<template v-slot:cell(archive)="data">
								<CInputCheckbox :disabled="editingDisabled" :custom="true" :checked.sync="data.item.archive" @change="change_archive(data.item, 'Envelope')"  label="Arhiva" inline/>
							</template>
							<template v-slot:cell(file_size)="data">
								<span v-if="data.item.file_size != null"> {{ data.item.file_size }} bytes</span>
								<span v-else> Ni podatka </span>
							</template>
						</b-table>
						<b-table
							v-if="ebill.ebill_file != null"
							bordered
							small
							class="mt-0 mb-0"
							striped
							hover
							thead-class="d-none"
							:items="[ebill.ebill_file]"
							:fields="filesTableProps.fields">
							<template v-slot:cell(actions)="data">
								<div class="float-right">
									<b-button :disabled="!data.item.filepath" class="mr-2" :title="!data.item.filepath ? 'Datoteka na strežniku ni najdena' : 'Predogled'" variant="primary" @click.stop="getFileFromServer(data.item, 'preview-toPdf')" size="sm">
										<CIcon name="cil-zoom-in"/>
									</b-button>
									<b-button :disabled="!data.item.filepath" :title="!data.item.filepath ? 'Datoteka na strežniku ni najdena' : 'Prenesi prilogo'" variant="primary" @click.stop="getFileFromServer(data.item)" size="sm">
										<CIcon name="cil-vertical-align-bottom"/>
									</b-button>
								</div>
							</template>
							<template v-slot:cell(archive)="data">
								<CInputCheckbox :disabled="editingDisabled" :custom="true" :checked.sync="data.item.archive" @change="change_archive(data.item, 'EbillFile')"  label="Arhiva" inline/>
							</template>
							<template v-slot:cell(file_size)="data">
								<span v-if="data.item.file_size != null"> {{ data.item.file_size }} bytes</span>
								<span v-else> Ni podatka </span>
							</template>
						</b-table>
						<b-table
							v-if="ebill.ebill_attachment"
							bordered
							small
							class="mt-0 mb-0"
							striped
							hover
							thead-class="d-none"
							:items="ebill.ebill_attachment"
							:fields="filesTableProps.fields">
							<template v-slot:cell(actions)="data">
								<div class="float-right">
									<b-button :disabled="!data.item.filepath" class="mr-2" :title="!data.item.filepath ? 'Datoteka na strežniku ni najdena' : 'Predogled'" variant="primary" @click.stop="getFileFromServer(data.item, 'preview')" size="sm">
										<CIcon name="cil-zoom-in"/>
									</b-button>
									<b-button :disabled="!data.item.filepath" :title="!data.item.filepath ? 'Datoteka na strežniku ni najdena' : 'Prenesi prilogo'" variant="primary" @click.stop="getFileFromServer(data.item)" size="sm">
										<CIcon name="cil-vertical-align-bottom"/>
									</b-button>
									<b-button v-if="!editingDisabled" class="ml-2" title='Odstrani prilogo' variant="danger" @click.stop="click_ebillAttachmentDelete(data.item)" size="sm">
										<CIcon name="cil-trash"/>
									</b-button>
								</div>
							</template>
							<template v-slot:cell(archive)="data">
								<CInputCheckbox :disabled="editingDisabled" :custom="true" :checked.sync="data.item.archive" @change="change_archive(data.item, 'EbillAttachment')"  label="Arhiva" inline/>
							</template>
							<template v-slot:cell(file_size)="data">
								<span v-if="data.item.file_size != null"> {{ data.item.file_size }} bytes</span>
								<span v-else> Ni podatka </span>
							</template>
						</b-table>
						<b-table
							v-if="ebill.order"
							bordered
							class="mt-0"
							small
							thead-class="d-none"
							striped
							hover
							:items="ebill.order.order_attachments"
							:fields="filesTableProps.fields">
							<template v-slot:cell(actions)="data">
								<div class="float-right">
									<b-button :disabled="!data.item.filepath" class="mr-2" :title="!data.item.filepath ? 'Datoteka na strežniku ni najdena' : 'Predogled'" variant="primary" @click.stop="getFileFromServer(data.item, 'preview')" size="sm">
										<CIcon name="cil-zoom-in"/>
									</b-button>
									<b-button :disabled="!data.item.filepath" :title="!data.item.filepath ? 'Datoteka na strežniku ni najdena' : 'Prenesi prilogo'" variant="primary" @click.stop="getFileFromServer(data.item)" size="sm">
										<CIcon name="cil-vertical-align-bottom"/>
									</b-button>
								</div>
							</template>
							<template v-slot:cell(archive)="data">
								<CInputCheckbox :disabled="editingDisabled" :custom="true" :checked.sync="data.item.archive" @change="change_archive(data.item, 'OrderAttachment')"  label="Arhiva" inline/>
							</template>
							<template v-slot:cell(file_size)="data">
								<span v-if="data.item.file_size != null"> {{ data.item.file_size }} bytes</span>
								<span v-else> Ni podatka </span>
							</template>
						</b-table>
					</div>
				</b-card>
			</b-col>
		</b-row>
		<b-row class="mt-4">
			<b-col sm=1>
				<b-button v-if="ebill.prepared_time == null && ebill.ebill_file != null && ebill.archived_at == null" class="ml-2" title="Označi e-račun kot pripravljen" variant="primary" @click.stop="prepareEbill(ebill)" size="sm">
					Pripravi
				</b-button>
				<b-button v-if="permissionCheck.can('ebills-actions') && ebill.prepared_time != null && ebill.archived_at == null" class="ml-2" title="Zavrni e-račun" variant="danger" @click.stop="rejectEbill(ebill)" size="sm">
					Zavrni
				</b-button>
			</b-col>
		</b-row>
		<vue-toastr ref="toastr"></vue-toastr>
		<order-create-edit-modal ref="orderCreateEditModal" @emit_orderCreatedOrEdited="emit_orderCreatedOrEdited"/>
		<client-create-edit-modal ref="clientCreateEditModal" @emit_clientCreatedOrEdited="emit_clientCreatedOrEdited"/>
	</b-card>
</template>

<script>
import PermissionCheck from '@/tools/permission-check';
import { EBILL_PREPARE, EBILL_REJECT, CHANGE_CLIENT, CHANGE_ORDER } from "@/store/actions/ebills";
import { CLIENTS_LIST_ALL } from "@/store/actions/clients";
import { FILE_DOWNLOAD, CHANGE_ARCHIVE } from "@/store/actions/files";
import { EBILL_ATTACHMENT_DELETE } from "@/store/actions/ebillsAttachments";
import { ORDERS_LIST_FILTERED } from "@/store/actions/orders";
import OrderCreateEditModal from '@/views/modals/OrderCreateEdit';


export default {
	name: 'ebill-details-in-table',
	components: {
		'order-create-edit-modal': OrderCreateEditModal
	},
	props: ['ebill', 'clientModal'],
	methods: {
		getFileFromServer: function (item, actionType) {
			this.isLoading = true;
			let eslogToPdf;
			if (actionType === 'preview-toPdf') {
				eslogToPdf = true;
			}
			return this.$store.dispatch(FILE_DOWNLOAD, { filepath: item.filepath, eslogToPdf: eslogToPdf })
				.then((response) => {
					this.isLoading = false;
					if (actionType === 'preview' || actionType === 'preview-toPdf') {
						var newBlob = new Blob([response.data], {type: response.headers['content-type']});
						const data = window.URL.createObjectURL(newBlob);
						window.open(data, '_blank');
					} else {
						let configData = JSON.parse(response.config.data);
						var newBlob = new Blob([response.data], {type: response.headers['content-type']});
						const data = window.URL.createObjectURL(newBlob);
						var link = document.createElement('a');
						link.href = data;
						link.download = item.orginal_filename;
						link.click();
						setTimeout(function () {
							// For Firefox it is necessary to delay revoking the ObjectURL
							window.URL.revokeObjectURL(data)
						}, 100);
					}
				})
				.catch(error => {
					this.isLoading = false;
					console.log(error);
					this.$refs.toastr.e('Napaka! Prosimo poizkusite kasneje.');
				});
		},
		prepareEbill: function (item) {
			this.isLoading = true;
			this.$store.dispatch(EBILL_PREPARE, { id: item.id })
				.then((responseData) => {
					if (responseData.message === 'prepared') {
						this.$refs.toastr.s('E-račun #' + item.id + ' uspešno pripravljen.');
						this.$emit('emit_ebillPrepared', responseData.preparedEbillId);
					} else {
						this.$refs.toastr.e('Neznan odziv strežnika');
					}
					this.isLoading = false;
				})
				.catch(error => {
					this.isLoading = false;
					console.error(error);
					if(error.response && error.response.data && error.response.data.code) {
						this.$refs.toastr.e('Napaka pri pripravi e-računa! (' + error.response.data.code + ')');
					} else {
						this.$refs.toastr.e('Napaka pri pripravi e-računa! Prosimo poizkusite kasneje.');
					}
				});
		},
		rejectEbill: function (item) {
			this.isLoading = true;
			this.$store.dispatch(EBILL_REJECT, { id: item.id })
				.then((responseData) => {
					if (responseData.message === 'rejected') {
						this.$refs.toastr.s('E-račun #' + item.id + ' uspešno zavrnjen.');
						this.$emit('emit_ebillRejected', responseData.rejectedEbillId);
					} else {
						this.$refs.toastr.e('Neznan odziv strežnika');
					}
					this.isLoading = false;
				})
				.catch(error => {
					this.isLoading = false;
					console.error(error);
					if(error.response && error.response.data && error.response.data.code) {
						this.$refs.toastr.e('Napaka pri zavrnitvi e-računa! (' + error.response.data.code + ')');
					} else {
						this.$refs.toastr.e('Napaka pri zavrnitvi e-računa! Prosimo poizkusite kasneje.');
					}
				});
		},
		change_clientsSelect: function (client) {
			this.isLoading = true;
			let clientId = client ? client.id : 0;
			this.$store.dispatch(CHANGE_CLIENT, { ebillId: this.ebill.id, clientId: clientId })
				.then((responseData) => {
					this.isLoading = false;
					this.ebill.client = responseData.client;
					if (this.ebill.client != null) {
						this.refreshAvailableOrders(this.ebill.client.id);
					}
					this.initialClient = responseData.client;
					this.$refs.toastr.s('Stranka uspešno spremenjena.');
				})
				.catch(error => {
					this.isLoading = false;
					this.ebill.client = this.initialClient;
					console.error(error);
					if(error.response && error.response.data && error.response.data.code) {
						this.$refs.toastr.e('Napaka pri spreminjanju stranke! (' + error.response.data.code + ')');
					} else {
						this.$refs.toastr.e('Napaka pri spreminjanju stranke! Prosimo poizkusite kasneje.');
					}
				});
		},
		filter_clientsSelect: (option, label, search) => {
			let s = search.toLowerCase();
			let companyTitle = (option.company_title ? option.company_title.toLowerCase() : '');
			let firstName = (option.first_name ? option.first_name.toLowerCase() : '');
			let lastName = (option.last_name ? option.last_name.toLowerCase() : '');
			let email = (option.email ? option.email.toLowerCase() : '');
			let companyTitleResult = (companyTitle.indexOf(s) > -1);
			let firstNameResult = (firstName.indexOf(s) > -1);
			let lastNameResult = (lastName.indexOf(s) > -1);
			let emailResult = (email.indexOf(s) > -1);
			return companyTitleResult || emailResult || firstNameResult || lastNameResult;
		},
		getClientSelectLabel: function(client) {
			let label = '';
			if (client.is_company) {
				label += client.company_title ? client.company_title + ' ' : '';
				label += client.email ? '(' + client.email + ')' : '';
			} else {
				label += client.first_name ? client.first_name + ' ' : '';
				label += client.last_name ? client.last_name + ' ' : '';
				label += client.email ? '(' + client.email + ')' : '';
			}
			return label;
		},
		click_ebillAttachmentDelete: function (data) {
			this.$bvModal.msgBoxConfirm('Želite izbrisati prilogo ' + data.orginal_filename + '(#' + data.id + ')?', {
				title: 'Potrdite brisanje priloge',
				size: 'sm',
				buttonSize: 'sm',
				okVariant: 'danger',
				okTitle: 'DA',
				cancelTitle: 'NE',
				footerClass: 'p-2',
				hideHeaderClose: false,
				centered: true
			}).then(value => {
				if (value === true) {
					this.isLoading = true;
					this.$store.dispatch(EBILL_ATTACHMENT_DELETE, { id: data.id })
						.then((responseData) => {
							this.isLoading = false;
							this.ebill.ebill_attachment = this.ebill.ebill_attachment.filter(function(obj) {
								return obj.id !== data.id;
							});
							this.$refs.toastr.s('Priloga ' + data.orginal_filename + '(#' + data.id + ') uspešno izbrisana.');
						})
						.catch(error => {
							this.isLoading = false;
							console.error(error);
							if(error.response && error.response.data && error.response.data.code) {
								this.$refs.toastr.e('Napaka pri brisanju! (' + error.response.data.code + ')');
							} else {
								this.$refs.toastr.e('Napaka pri brisanju! Prosimo poizkusite kasneje.');
							}
						});
				}
			}).catch(err => {
				console.error(err);
			})
		},
		change_ordersSelect: function (order) {
			let orderId = null;
			if (order != null) {
				if (order.id == null) {
					let modalInput = {
						mode: 3, // create with order
						order: order.num_order,
						clientId: this.ebill.client.id
					}
					this.$refs.orderCreateEditModal.showModal(modalInput);
				} else {
					orderId = order.id;
					this.changeOrder(this.ebill.id, orderId);
				}
			} else {
				this.changeOrder(this.ebill.id, null);
			}
		},
		filter_ordersSelect: (option, label, search) => {
			let s = search.toLowerCase();
			let numOrder = (option.num_order ? option.num_order.toLowerCase() : '');
			let numOrderResult = (numOrder.indexOf(s) > -1);
			return numOrderResult;
		},
		emit_orderCreatedOrEdited: function (data) {
			if (data.message === 'created') {
				this.changeOrder(this.ebill.id, data.order.id);
				this.selectedOrder = data.order;
				this.refreshAvailableOrders(clientId);
			} else {
				this.showAlert('Neznan odziv strežnika.', this.ALERT_TYPE_ERROR, 3);
				this.$refs.ordersOfClientTable.refresh();
			}
		},
		click_orderCreate: function () {
			let modalInput = {
				mode: 1, // create
				order: null,
				clientId: this.ebill.client.id
			}
			this.$refs.orderCreateEditModal.showModal(modalInput);
		},
		refreshAvailableOrders: function (clientId) {
			this.avaiableOrdersList.inputFilter.clientId = clientId;
			this.avaiableOrders = null;
			this.$store.dispatch(ORDERS_LIST_FILTERED, { tableProps: this.avaiableOrdersList })
			.then((responseData) => {
				this.avaiableOrders = responseData.allOrders;
			})
			.catch(error => {
				console.log(error);
				this.avaiableOrders = false;
			});
		},
		changeOrder: function (ebillId, orderId) {
			this.isLoading = true;
			this.$store.dispatch(CHANGE_ORDER, { ebillId: ebillId, orderId: orderId })
				.then((responseData) => {
					this.isLoading = false;
					this.ebill.order = responseData.order;
					this.$refs.toastr.s('Naročilnica uspešno spremenjena.');
				})
				.catch(error => {
					this.isLoading = false;
					this.selectedOrder = this.ebill.order;
					console.error(error);
					if(error.response && error.response.data && error.response.data.code) {
						this.$refs.toastr.e('Napaka pri spreminjanju naročilnice! (' + error.response.data.code + ')');
					} else {
						this.$refs.toastr.e('Napaka pri spreminjanju naročilnice! Prosimo poizkusite kasneje.');
					}
				});
		},
		getNumOrderAttachments: function(order) {
			if (order.order_attachments != null && order.order_attachments.length > 0) {
				return order.order_attachments.length;
			}
			return 0;
		},
		emit_clientCreatedOrEdited: function (data) {
			if (data.message === 'edited') {
				this.$refs.toastr.s('Stranka ' + this.mixinGetClientFullName(data.client) + ' uspešno urejena.');
				this.$emit('emit_ebillClientEdited', this.ebill.id);
			} else {
				this.$refs.toastr.e('Neznan odziv strežnika.');
			}
		},
		editClient: function () {
			let modalInput = {
				mode: 3, // edit details only
				client: this.ebill.client
			}
			this.$refs.clientCreateEditModal.showModal(modalInput);
		},
		change_archive: function(file, model) {
			this.isLoading = true;
			this.$store.dispatch(CHANGE_ARCHIVE, { id: file.id, model: model })
				.then((responseData) => {
					this.isLoading = false;
				})
				.catch(error => {
					this.isLoading = false;
					file.archive = !file.archive;
					console.error(error);
					if(error.response && error.response.data && error.response.data.code) {
						this.$refs.toastr.e('Napaka pri spreminjanju parametra arhiva! (' + error.response.data.code + ')');
					} else {
						this.$refs.toastr.e('Napaka pri spreminjanju parametra arhiva! Prosimo poizkusite kasneje.');
					}
				});
		}
	},
	data: function () {
		return {
			isLoading: false,
			isLoadingSearch: false,
			filesTableProps: {
				fields: [
					{ key: 'orginal_filename', label: 'Priloge', sortable: true, class: '' },
					{ key: 'description', label: 'Opis', sortable: true, class: 'tdFileDescription' },
					{ key: 'type', label: 'Tip datoteke', sortable: false, class: 'tdFileType' },
					{ key: 'file_size', label: 'Velikost (B)', sortable: false, class: 'tdFileType' },
					{ key: 'archive', label: 'Arhiva', sortable: true, class: 'tdFileType' },
					{ key: 'actions', label: '#', sortable: true, class: 'tdFilesActions' }
				],
			},
			initialClient: null,
			avaiableOrdersList: {
				inputFilter: {
					clientId: null,
					getAll: true
				}
			},
			avaiableOrders: null,
			selectedOrder: null,
			permissionCheck: PermissionCheck
		};
	},
	created() {
		this.initialClient = this.ebill.client;
		this.selectedOrder = this.ebill.order;
		if (this.initialClient != null) {
			this.refreshAvailableOrders(this.initialClient.id);
		}
	},
	computed: {
		allClients: function() {
			if (this.$store.getters.getAllClients) {
				return this.$store.getters.getAllClients;
			} else {
				this.$store.dispatch(CLIENTS_LIST_ALL)
					.then((responseData) => {
						console.log(responseData);
						return responseData.clients;
					})
					.catch(error => {
						console.error(error);
					});
			}
		},
		editingDisabled: function () {
			return this.ebill.archived_at != null || (!this.permissionCheck.is('admin') && this.ebill.prepared_time != null)
		}
	}
};
</script>
<style>
.tdFileDescription {
	width: 280px;
}
.tdFileType {
	width: 180px;
}
.tdFilesActions {
	width: 180px;
}
</style>